import { useEffect } from "react";

export function ChoiceRadioButton({ questionNumber, name, labelText, register, isRequired, errors, errorMsg, index, disabled, checked, isRelated, id, onChange, ...rest }) {

    let errorId = id + "error";

    let yesValue;
    let noValue;

    useEffect(() => {
        if (checked) {
            yesValue = true;
        } else {
            noValue = true;
        }
        console.log(errorId);
    }, [])

    return (
        <>
            <div className={`col-span-4 ${index === 0 ? "pt-10" : "pt-6"}`}>
                <div className={`flex md:text-lg lg:text-lg`}>
                    <label className="">{questionNumber}</label>
                    <label className="pb-3">{labelText}</label>
                </div>

                {errors[name] && isRequired ? <div className="mt-1.5 font-bold text-xs pl-[14px] md:pl-[14px] lg:pl-[18px] col-span-2 lg:col-span-1 pb-2" data-required="true">{errorMsg}</div> : <></>}
                <div id={errorId} className={`mt-1.5 font-bold text-xs pl-[14px] md:pl-[14px] lg:pl-[18px] col-span-2 lg:col-span-1 pb-2`}></div>

                <div className={`flex pl-4 ${isRelated ? "border-l-[0.5px] border-midnight-color" : ""}`}>
                    <input type="radio" onChange={onChange} id={id} name="n1" value="yes" disabled={disabled} checked={yesValue} className="w-[18px] accent-checkbox-blue" {...register(name, { required: isRequired })} /><span className="pl-2 text-sm">Vero</span>
                    <input type="radio" onChange={onChange} id={id} name="n1" value="no" disabled={disabled} checked={noValue} className="w-[18px] accent-checkbox-blue ml-8" {...register(name, { required: isRequired })} /><span className="pl-2 text-sm ">Falso</span>
                </div>

            </div>
        </>
    )
}