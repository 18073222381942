import { Navigate, Route, Routes } from "react-router-dom"
import FormBando from "./pages/form-bando";
import DettaglioBando from "./pages/dettaglio-bando";

function App() {
  return <Routes>

    <Route path="/" element={<Navigate to="/dettagli-bando" />} />

    <Route path="/dettagli-bando" element={<DettaglioBando/>}/>
    <Route path="/form-bando" element={<FormBando />}></Route>

  </Routes>
}

export default App;
