import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export function LabeledTextInput({ labelText, placeholderText, name, register, isRequired, errors, errorMsg, minLength, maxLength, titleExists, value, valueOfRel, col_rel, setUserVoteTrue, setUserVoteFalse, form, checkCFmethod, modalCFtrigger, setModalCFtrigger, ...rest }) {

    const [isCF, setIsCF] = useState(false);

    const [isVote, setIsVote] = useState(false);
    const [voteValue, setVoteValue] = useState();
    let badFormatMessage = "Il voto deve essere in centesimi oppure in sessantesimi";

    const [oldCF, setOldCF] = useState();

    var regEx = /^[0-9]+\/[0-9]+$/;
    var numberRegEx = /^\d+$/;

    const formLabel = form;
    const { getValues, setValue } = formLabel || {};

    useEffect(() => {
        console.log("LABEL NAME: " + name);
        console.log("does " + name + " contain voto: ", name?.includes('voto'));
        if (name?.includes('voto') || name?.includes('votazione')) {
            console.log("SETTATO VOTO A TRUE");
            setIsVote(true);
        }

        if (name?.includes("cf") || name?.includes("CF") || name?.includes("CodiceFiscale") || name?.includes("codicefiscale") || name?.includes("CODICEFISCALE") || name?.includes("CodiceFiscale") || name?.includes("codiceFiscale")) {
            setIsCF(true);
        }

        console.log("isVote: ", isVote);
        console.log("isCF : ", isCF);
    }, [])

    useEffect(() => {
        if (voteValue === false) {
            setUserVoteFalse();
        } else {
            setUserVoteTrue();
        }
    }, [voteValue]);

    useEffect(() => {
        if(valueOfRel === false){
            setValue(name, "");
            setUserVoteTrue();
        }
    }, [valueOfRel])

    const checkIfCorrectFormat = () => {
        // console.log("PROVA");
        if (getValues(name) && getValues(name)?.includes('/') && getValues(name)?.length >= minLength && getValues(name)?.length <= maxLength && getValues(name)?.length != 0) {
            const split = getValues(name)?.split("/");
            console.log("getValues(name): " + getValues(name));
            if (split?.length > 1) {

                if (numberRegEx.test(split[0]) === true && numberRegEx.test(split[1]) === true) {
                    if (voteValue != true) {
                        setVoteValue(true);
                    }
                } else {
                    if(voteValue != false){
                        setVoteValue(false);
                    }
                }

            }
        } else {
            if(voteValue != false){
                setVoteValue(false);
            }
        }
    }

    const checkCFexists = () => {
        //console.log("CHECK CF EXISTS!");
        if (getValues(name).length === 16) {
            //console.log("CF LENGTH OK!");
            if(modalCFtrigger === true && oldCF != getValues(name)){
                setModalCFtrigger();
            }
            if(getValues(name) != oldCF){
                setOldCF(getValues(name))
            }
            checkCFmethod();
        }
    }

    return (
        <>
            <div
            style={{
                alignSelf: "center"
            }}
            className={`flex flex-col min-w-187px-mobile max-w-187px-mobile md:min-w-[276px] lg:min-w-[276px] min-h-[76px] ${titleExists ? "mt-4" : "mt-8"} ${col_rel === "col-span-2" ? 'col-span-2' : col_rel === "col-span-3" ? 'col-span-3' : col_rel === "col-span-4" ? 'col-span-4' : ""}`}>
                <label className={`pb-3 ${valueOfRel && valueOfRel === true && errors[name] && isRequired ? "text-error-color" : ""} ${valueOfRel === undefined && errors[name] && isRequired ? "text-error-color" : ""} ${isVote != undefined && voteValue === false && valueOfRel && valueOfRel === true ? "text-error-color" : ""} ${valueOfRel === false ? "text-disabled-color pointer-events: none" : ""} ${isVote ? "text-[11px]" : ""}`}>{labelText}{isRequired ? "*" : ""}</label>
                <input type="text" placeholder={isVote ? ".../..." : placeholderText} name={name} onChange={isVote ? checkIfCorrectFormat() : isCF ? checkCFexists() : ""} disabled={valueOfRel != undefined && valueOfRel === false} {...register(name, { required: valueOfRel != undefined ? isRequired && valueOfRel === true : isRequired, minLength: minLength, maxLength: maxLength },)} {...rest} className={`border-[1px] rounded-md p-4 text-xs ${valueOfRel && valueOfRel === true && errors[name] && isRequired ? "border-error-color placeholder-error-color text-error-color" : ""} ${valueOfRel === undefined && errors[name] && isRequired ? "border-error-color placeholder-error-color text-error-color" : ""} ${isVote != undefined && voteValue === false && valueOfRel && valueOfRel === true ? "border-error-color placeholder-error-color text-error-color" : ""}`}></input>
                {(valueOfRel === undefined || valueOfRel === true) && errors[name]?.type === "required" && isRequired ? <div className="mt-1.5 font-bold text-xs" data-required="true">Campo Obbligatorio</div> : <></>}
                {(valueOfRel === undefined || valueOfRel === true) && errors[name]?.type === "minLength" && isRequired ? <div className="mt-1.5 font-bold text-xs">Questo campo ha una lunghezza minima di {minLength} caratteri</div> : <></>}
                {(valueOfRel === undefined || valueOfRel === true) && errors[name]?.type === "maxLength" && isRequired ? <div className="mt-1.5 font-bold text-xs">Questo campo ha una lunghezza massima di {maxLength} caratteri</div> : <></>}
                {/* {valueOfRel && valueOfRel === true && errors[name] && isRequired ? <div className="mt-1.5 font-bold text-xs" data-required="true">{errorMsg}</div> : <></>} */}
                {valueOfRel && valueOfRel === true && isVote && getValues(name) && !getValues(name).includes('/') && (
                    <div>
                        <div className="mt-1.5 font-bold text-xs">{badFormatMessage}</div>
                    </div>
                )}
                {valueOfRel && valueOfRel === true && isVote && !getValues(name)?.match(regEx) && (
                    <div>
                        <div className="mt-1.5 font-bold text-xs">Questo campo può contenere soltanto numeri.</div>
                    </div>
                )}
            </div>
        </>
    )

}