import { EmptyButton } from './empty-button';
import { FullButton } from './full-button';

export function ThankYouComponent() {
    return (

        <>
            <div className="">
                <div className="pt-16">
                    <img src={require('../img/thank-you-img.png')} className="mx-auto"></img>
                </div>

                <div className="w-3/4 pt-10 mx-auto flex flex-col text-lg text-midnight-color text-center">
                    <span>Grazie!</span>
                    <span>La tua candidatura è stata inviata con successo.</span>

                    <div className="pt-4">
                        <span>A breve riceverai un’email con il tuo codice candidatura. <br/> Si raccomanda di controllare anche la casella di SPAM.</span>
                    </div>
                </div>

            </div>

        </>



    )
}