export function NumberInput({ labelText, placeholderText, name, register, isRequired, errors, errorMsg, min, max, isInSubGrid, valueOfRel, ...rest }) {

    return (
        <>
            <div className={`flex flex-col min-w-[236px] md:min-w-[276px] lg:min-w-[276px] min-h-[76px] ${isInSubGrid ? "mt-4" : "mt-8"}`}>
                <label className={`pb-3 ${valueOfRel && valueOfRel === true && errors[name] && isRequired ? "text-error-color" : ""} ${valueOfRel === undefined && errors[name] && isRequired ? "text-error-color" : ""} ${valueOfRel === false ? "text-disabled-color pointer-events: none" : ""}`}>{labelText}{isRequired ? "*" : ""}</label>
                <input type="number" placeholder={placeholderText} name={name} disabled={valueOfRel != undefined && valueOfRel === false} {...register(name, { required: valueOfRel != undefined ? isRequired && valueOfRel === true : isRequired, min: min, max: max })} className={`border-[1px] rounded-md p-4 text-xs ${valueOfRel && valueOfRel === true && errors[name] && isRequired ? "border-error-color placeholder-error-color text-error-color" : ""} ${valueOfRel === undefined && errors[name] && isRequired ? "border-error-color placeholder-error-color text-error-color" : ""}`}></input>
                {(valueOfRel === undefined || valueOfRel === true) && errors[name]?.type === "required" && isRequired ? <div className="mt-1.5 font-bold text-xs" data-required="true">Campo Obbligatorio</div> : <></>}
                {(valueOfRel === undefined || valueOfRel === true) && errors[name]?.type === "min" && isRequired ? <div className="mt-1.5 font-bold text-xs">Questo campo deve avere un valore minimo di {min}</div> : <></>}
                {(valueOfRel === undefined || valueOfRel === true) && errors[name]?.type === "max" && isRequired ? <div className="mt-1.5 font-bold text-xs">Questo campo deve avere un valore massimo di {max}</div> : <></>}
                {/* {valueOfRel && valueOfRel === true && errors[name] && isRequired ? <div className="mt-1.5 font-bold text-xs" data-required="true">{errorMsg}</div> : <></>}*/}</div> 
        </>
    )
}