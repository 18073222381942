import { useForm } from 'react-hook-form';

export function CheckboxComponent({ labelText, name, register, isRequired, errors, errorMsg, index, id, onChange, ...rest }) {
    return (
        <>
            <div className={`flex col-span-4 ${index === 0 ? "pt-10" : "pt-6"}`}>
                <input type="checkbox" id={id} name={name} onChange={onChange} {...register(name, {required: isRequired})} {...rest} className={`w-[14px] h-[14px] md:w-[18px] md:h-[18px] lg:w-[18px] lg:h-[18px] my-auto accent-checkbox-blue color-checkbox-blue border-checkbox-blue flex-none ${errors[name] && isRequired ? "accent-error-color" : ""}`} required></input>
                <p className="pl-4 md:text-lg lg:text-lg">{labelText}</p>
            </div>
            {errors[name] && isRequired ? <div className="mt-1.5 font-bold text-xs pl-[30px] md:pl-[34px] lg:pl-[34px] col-span-2 lg:col-span-1" data-required="true" data-required="true">{errorMsg}</div> : <></>}
        </>
    )
}