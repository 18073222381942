export function DateInput({ labelText, placeholderText, name, register, isRequired, errors, errorMsg, isInSubGrid, userAge, onChange, valueOfRel, ...rest }) {

    return (
        <>
            <div className={`flex flex-col min-w-[236px] md:min-w-[276px] lg:min-w-[276px] min-h-[76px] ${isInSubGrid ? "mt-4" : "mt-8"}`}>
                <label className={`pb-3 ${valueOfRel && valueOfRel === true && errors[name] && isRequired ? "text-error-color"  : ""} ${valueOfRel === undefined && errors[name] && isRequired ? "text-error-color" : "" } ${valueOfRel === false ? "text-disabled-color pointer-events: none" : ""} ${userAge && userAge < 18 ? "border-error-color placeholder-error-color text-error-color" : ""}`}>{labelText}{isRequired ? "*" : ""}</label>
                <input type="date" placeholder={placeholderText} disabled={valueOfRel != undefined && valueOfRel === false} onChange={userAge ? () => onChange(this) : ""} name={name} {...register(name, {required: valueOfRel != undefined ? isRequired && valueOfRel === true : isRequired,})} className={`border-[1px] rounded-md p-4 text-xs ${valueOfRel && valueOfRel === true && errors[name] && isRequired ? "border-error-color placeholder-error-color text-error-color" : ""} ${valueOfRel === undefined && errors[name] && isRequired ? "border-error-color placeholder-error-color text-error-color" : ""} ${userAge != undefined && userAge < 18 ? "border-error-color placeholder-error-color text-error-color" : ""} ${valueOfRel === false ? "text-disabled-color" : ""}`}></input>
                {valueOfRel === undefined && errors[name] && isRequired ? <div className="mt-1.5 font-bold text-xs" data-required="true">{errorMsg}</div> : <></>}
                {valueOfRel && valueOfRel === true && errors[name] && isRequired ? <div className="mt-1.5 font-bold text-xs" data-required="true">{errorMsg}</div> : <></>}
                {userAge && userAge < 18 ?  <div className="mt-1.5 font-bold text-xs">L'utente deve essere maggiorenne</div> : <></>}
            </div>
        </>
    )
}