import { useEffect, useState } from "react"
import { LabeledTextInput } from "./labeledInput";
import { CheckboxComponent } from "./checkbox";
import { DateInput } from "./dateInput";
import { EmailInput } from "./emailInput";
import { NumberInput } from "./numberInput";
import { ChoiceRadioButton } from "./yes-no-radiobutton";
import { FileInput } from "./fileInput";
import { SelectInput } from "./selectInput";

export function RadioGridComponent({ questionNumber, name, labelText, register, isRequired, errors, errorMsg, block, areaNumber, questionBlock, fieldIndex, ...rest }) {

    let [isDisabled, setDisabled] = useState(false);
    let [numberOfSubGrids, setNumberOfSubGrids] = useState(0);
    let [subGrids, setSubGrids] = useState(Array(numberOfSubGrids).fill(null));

    useEffect(() => {
        checkNumberOfSubGrids();
        setSubGrids(Array(numberOfSubGrids).fill(false));

        console.log("SubGrids after useEffect: ")

        for (let i = 0; i < subGrids.length; i++) {
            console.log(JSON.stringify(subGrids[i]))
        }

    }, [])

    const checkNumberOfSubGrids = () => {

        let subGridNumber = 0;
        let numberOfFields = questionBlock.fields;

        for (let i = 0; i < numberOfFields.length; i++) {
            if (numberOfFields[i].block) {
                subGridNumber++;
            }
        }

        setNumberOfSubGrids(subGridNumber);
    }

    const setGridState = (index, boolState) => {
        setSubGrids((prevSubGrids) => {
            const updatedSubGrids = [...prevSubGrids];
            updatedSubGrids[index] = boolState;
            return updatedSubGrids;
        });

        console.log("Grid [" + index + "] state: " + subGrids[index]);
    }

    return (
        <>
            <div className={`flex text-lg`}>
                <label className="">{questionNumber}.</label>
            </div>

            <div className="border-l-[0.5px] border-midnight-color radioGrid-div">
                <div className="radioGrid-labelButtons-wrapper">
                    <label className="pb-3 radioGrid-labelText text-lg leading-6">{labelText}</label>
                    <div className={`flex ${block ? "radioGrid-radioButton" : ""}`}>
                        <input type="radio" name="n1" value="yes" className="w-[18px] accent-checkbox-blue" {...register(name, { required: isRequired })} onChange={() => setGridState(fieldIndex, true)} /><span className="pl-2 text-sm">Sì</span>
                        <input type="radio" name="n1" value="no" className="w-[18px] accent-checkbox-blue ml-8" {...register(name, { required: isRequired })} onChange={() => setGridState(fieldIndex, false)} /><span className="pl-2 text-sm ">No</span>
                    </div>
                </div>
                {/*{errors[name] ? <div className="pl-6 mt-1.5 font-bold text-xs" data-required="true">{errorMsg}</div> : <></>}*/}

                <div className="flex flex-col">
                    <label className={`font-bold ${subGrids[fieldIndex] ? "text-disabled-midnight-color" : ""}`}>{block.title}</label>
                    <label className={`italic ${subGrids[fieldIndex] ? "text-disabled-midnight-color" : ""}`}>{block.subtitle}</label>

                    <div className={`${subGrids[fieldIndex] ? "pointer-events-none text-disabled-midnight-color placeholder-disabled-midnight-color" : ""} ${block.__component === "blocks.block" ? "grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-x-4 text-sm " : ""}`}>
                        {block.fields.map((questionAnswer) => {

                            if (questionAnswer.type === "text") {
                                return (
                                    <LabeledTextInput
                                        key={questionAnswer.slug}
                                        labelText={questionAnswer.label}
                                        placeholderText={questionAnswer.label}
                                        name={questionAnswer.slug}
                                        register={register}
                                        isRequired={subGrids[fieldIndex] ? false : true}
                                        minLength={questionAnswer.minlength}
                                        maxLength={questionAnswer.maxlength}
                                        errors={errors}
                                        errorMsg="Campo obbligatorio"
                                        isInSubGrid={true}
                                    />
                                )
                            }

                            if (questionAnswer.type === "checkbox") {
                                return (
                                    <CheckboxComponent
                                        key={questionAnswer.slug}
                                        name={questionAnswer.slug}
                                        labelText={questionAnswer.label}
                                        register={register}
                                        isRequired={subGrids[fieldIndex] ? false : true}
                                        errors={errors}
                                        errorMsg="Campo obbligatorio"
                                    />
                                )
                            }

                            if (questionAnswer.type === "date") {
                                return (
                                    <DateInput
                                        key={questionAnswer.slug}
                                        name={questionAnswer.slug}
                                        labelText={questionAnswer.label}
                                        placeholderText={questionAnswer.label}
                                        register={register}
                                        isRequired={subGrids[fieldIndex] ? false : true}
                                        errors={errors}
                                        errorMsg="Campo obbligatorio"
                                        isInSubGrid={true}
                                    />
                                )
                            }

                            if (questionAnswer.type === "email") {
                                return (
                                    <EmailInput
                                        key={questionAnswer.slug}
                                        name={questionAnswer.slug}
                                        labelText={questionAnswer.label}
                                        placeholderText={questionAnswer.label}
                                        register={register}
                                        isRequired={subGrids[fieldIndex] ? false : true}
                                        errors={errors}
                                        errorMsg="Campo obbligatorio"
                                        minLength={questionAnswer.minlength}
                                        maxLength={questionAnswer.maxlength}
                                        isInSubGrid={true}
                                    />
                                )
                            }

                            if (questionAnswer.type === "number") {
                                return (
                                    <NumberInput
                                        key={questionAnswer.slug}
                                        name={questionAnswer.slug}
                                        labelText={questionAnswer.label}
                                        placeholderText={questionAnswer.label}
                                        register={register}
                                        isRequired={subGrids[fieldIndex] ? false : true}
                                        min={questionAnswer.min}
                                        max={questionAnswer.max}
                                        errors={errors}
                                        errorMsg="Campo obbligatorio"
                                        isInSubGrid={true}
                                    />
                                )
                            }

                            if (questionAnswer.type === "radio") {
                                return (
                                    <ChoiceRadioButton
                                        key={questionAnswer.slug}
                                        name={questionAnswer.slug}
                                        labelText={questionAnswer.label}
                                        placeholderText={questionAnswer.label}
                                        register={register}
                                        isRequired={subGrids[fieldIndex] ? false : true}
                                        errors={errors}
                                        errorMsg="Campo obbligatorio"
                                    />
                                )
                            }

                            if (questionAnswer.type === "file") {
                                return (
                                    <FileInput
                                        key={questionAnswer.slug}
                                        name={questionAnswer.slug}
                                        labelText={questionAnswer.label}
                                        placeholderText={questionAnswer.label}
                                        register={register}
                                        isRequired={subGrids[fieldIndex] ? false : true}
                                        errors={errors}
                                        errorMsg="Campo obbligatorio"
                                        isInSubGrid={true}
                                    />
                                )
                            }

                            if (questionAnswer.type === "select") {
                                return (
                                    <SelectInput
                                        key={questionAnswer.slug}
                                        name={questionAnswer.slug}
                                        labelText={questionAnswer.label}
                                        placeholderText={questionAnswer.label}
                                        options={questionAnswer.options}
                                        register={register}
                                        isRequired={subGrids[fieldIndex] ? false : true}
                                        errors={errors}
                                        errorMsg="Campo obbligatorio"
                                        isInSubGrid={true}
                                    />
                                )
                            }

                        })}

                    </div>
                </div>

            </div>

        </>
    )
}