import { useForm } from "react-hook-form";

export function SelectInput({
  labelText,
  placeholderText,
  name,
  register,
  isRequired,
  errors,
  errorMsg,
  minLength,
  maxLength,
  options,
  isInSubGrid,
  valueOfRel,
  ...rest
}) {
  return (
    <>
      <div
        className={`flex flex-col min-w-[236px] md:min-w-[276px] lg:min-w-[276px] min-h-[76px] ${
          isInSubGrid ? "mt-4" : "mt-8"
        }`}
      >
        <label
          className={`pb-3 ${
            valueOfRel && valueOfRel === true && errors[name] && isRequired
              ? "text-error-color"
              : ""
          } ${
            valueOfRel === undefined && errors[name] && isRequired
              ? "text-error-color"
              : ""
          } ${
            valueOfRel === false
              ? "text-disabled-color pointer-events: none"
              : ""
          }${
            valueOfRel === false
              ? "text-disabled-color pointer-events: none"
              : ""
          }`}
          dangerouslySetInnerHTML={{
            __html: labelText + (isRequired ? "*" : ""  ),
          }}
        ></label>
        <select
          disabled={valueOfRel != undefined && valueOfRel === false}
          name={name}
          {...register(name, {
            required:
              valueOfRel != undefined
                ? isRequired && valueOfRel === true
                : isRequired,
          })}
          {...rest}
          className={`border-[1px] rounded-md p-4 text-xs ${
            valueOfRel && valueOfRel === true && errors[name] && isRequired
              ? "border-error-color placeholder-error-color"
              : ""
          } ${
            valueOfRel === undefined && errors[name] && isRequired
              ? "border-error-color placeholder-error-color"
              : ""
          }`}
        >
            <option value="" disabled selected>
                Selezionare un valore
            </option>
          {options?.map((param) => {
            
            return (
              <option key={param.value} value={param.value}>
                {param.label}
              </option>
            );
          })}
        </select>
        {valueOfRel === undefined && errors[name] && isRequired ? (
          <div className="mt-1.5 font-bold text-xs" data-required="true">{errorMsg}</div>
        ) : (
          <></>
        )}
        {valueOfRel && valueOfRel === true && errors[name] && isRequired ? (
          <div className="mt-1.5 font-bold text-xs" data-required="true">{errorMsg}</div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
