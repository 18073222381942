import { useEffect, useState } from "react"
import { EmptyButton } from "./empty-button";
import { FullButton } from "./full-button";

export function Modal({ open, onClose, proceedMethod }) {

    useEffect(() => {
        console.log("Modal chiamato!!!");
    }, [])

    return (
        <div onClick={onClose} className={`fixed inset-0 flex justify-center items-center transition-colors ${open ? "visible bg-black/20" : "invisible"}`}>
            <div className={`bg-white rounded-xl shadow p-6 transition-all w-2/3 ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}`} onClick={e => e.stopPropagation()}>

            <div className="flex justify-center items-center"> {/* HEADER */}
                    <label className={`text-midnight-color font-arial font-bold text-center text-2xl`}>
                        ATTENZIONE
                    </label>
                </div>
                
                
                <div className="flex justify-center items-center pt-6"> {/* BODY */}
                    <label className={`text-midnight-color font-arial font-bold text-center`}>
                        DOPO L'INVIO DELLA CANDIDATURA, ASSICURARSI CHE LA CANDIDATURA SIA ANDATA A BUON FINE ( ATTENDERE CHE SULLA SCHERMATA COMPAIA IL MESSAGGIO "LA CANDIDATURA E' STATA INVIATA CON SUCCESSO")<br /> IN CASO CONTRARIO, RIPETERE LA PROCEDURA DALL'INIZIO.
                    </label>
                </div>

                <div className={`flex items-center justify-center mt-12 pb-8`}> {/* FOOTER */}
                    <EmptyButton buttonText="Annulla" methodToCall={onClose} />
                    <FullButton buttonText="Conferma e procedi" methodToCall={() => proceedMethod()} />
                </div>
            </div>
        </div>
    )
}