const UtilityService = {
    getUrlParams: (code) => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        return params.get(code);
    },
    getLocalStorage: function (){
        let data = JSON.parse(localStorage.getItem("form"));
        return data;
    },

    setLocalStorage: function (data){
        localStorage.setItem("form", JSON.stringify(data));
    }
}

export default UtilityService;