export function FullButton({ buttonText, idForm, methodToCall, disabled = false }) {

    if (methodToCall) {
        return (
            <>
                <button type="submit" style={
                    disabled ? { backgroundColor: '#BDBDBD' } : { backgroundColor: '#1E3A8A' }
                } disabled={disabled} form={idForm} value="button" onClick={methodToCall} className="ml-6 w-[140px] md:w-[220px] lg:w-[200px] lg:w-[200px] h-10 bg-midnight-color text-white rounded-md font-bold text-xs md:text-base lg:text-base">{buttonText}</button>
            </>
        )
    } else {
        return (
            <>
                <button type="submit" style={
                    disabled ? { backgroundColor: '#BDBDBD' } : { backgroundColor: '#1E3A8A' }
                } disabled={disabled} form={idForm} value="Submit" className="ml-6 w-[140px] md:w-[220px] lg:w-[200px] lg:w-[200px] h-10 bg-midnight-color text-white rounded-md font-bold text-xs md:text-base lg:text-base">{buttonText}</button>
            </>
        )
    }

}