import axios from "axios";
const bearer = process.env.REACT_APP_BASETOKEN;

const DataService = {
  apiBasePath: function() {
    // return "http://localhost:1337/api";
    return "https://admincandidature.eavsrl.it/api";
    //return "http://192.168.10.166:1337/api";
    //return "http://eav.fiven.eu:2086/api";
  },

  getFormBando: function(setForm, setInternal, modifyJson, code) {
    return axios
      .get(this.apiBasePath() + "/forms/" + code + "/data",{
        headers: {
          Authorization: `Bearer ${bearer}`
        }
      })
      .then((res) => {
        if (modifyJson) {
          modifyJson(res.data);
        }
        setForm(res.data);
        setInternal(res.data.internal);
      });
  },

  getFormDettaglio: function(setForm, modifyJson, code) {
    return axios
      .get(this.apiBasePath() + "/forms/" + code + "/data", {
        headers: {
          Authorization: `Bearer ${bearer}`
        }
      })
      .then((res) => {
        if (modifyJson) {
          modifyJson(res.data);
        }
        setForm(res.data);
      });
  },

  sendForm: function(jsonForm) {
    return axios
      .post(this.apiBasePath() + "/applications", {
        data: {
          form: jsonForm.id,
          payload: jsonForm,
        },
      },{
        headers: {
          Authorization: `Bearer ${bearer}`
        }
      })
      .then((res) => console.log("Invio Form andato a buon fine!"))
      .catch(function(error) {
        console.log("Errore!");
        console.log("JSON FORM : ", jsonForm);
        if (error.res) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.res.data);
          console.log(error.res.status);
          console.log(error.res.headers);
        } else if (error.request) {
          // The request was made but no res was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          alert("Error", error.message);
        }
        console.log(error.config);
      });
  },

  sendFormResponsabile: function(jsonForm, nome, email) {
    return axios
      .post(this.apiBasePath() + "/applications", {
        data: {
          form: jsonForm.id,
          payload: jsonForm,
          ccname: nome,
          ccemail: email,
        },
      }, {
        headers: {
          Authorization: `Bearer ${bearer}`
        }
      })
      .then((res) => console.log("Invio Form andato a buon fine!"))
      .catch(function(error) {
        console.log("Errore!");
        console.log("JSON FORM : ", jsonForm);
        if (error.res) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.res.data);
          console.log(error.res.status);
          console.log(error.res.headers);
        } else if (error.request) {
          // The request was made but no res was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          alert("Error", error.message);
        }
        console.log(error.config);
      });
  },

  uploadFiles_backup: function(files) {
    return axios
      .post(this.apiBasePath() + "/upload", files, {
        headers: {
          Authorization: `Bearer ${bearer}`
        }
      })
      .then((res) => {
        console.log("Upload file andato a buon fine!");
        console.log("upload file res: " + res.data);
      })
      .catch(function(error) {
        console.log("Errore!");
        if (error.res) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.res.data);
          console.log(error.res.status);
          console.log(error.res.headers);
        } else if (error.request) {
          // The request was made but no res was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          alert("Error", error.message);
        }
        console.log(error.config);
      });
  },

  uploadFile: async function(files, i, callback) {
    const formData = new FormData();

    console.log("files[i] : ", files[i]);
    const _file = files[i].file;
    formData.append("files", files[i].file);

    const r = await fetch(this.apiBasePath() + "/upload", {
      method: "post",
      body: formData,
      headers: {
        Authorization: `Bearer ${bearer}`
      }
    });

    const res = await r.json();
    console.log(res);
    try {
      files[i].path = res.map((r) => r.url);
    } catch (error) {}

    if (i < files.length - 1) {
      this.uploadFile(files, i + 1, callback);
    } else {
      callback && callback();
    }
  },

  uploadFiles: function(files, callback) {
    this.uploadFile(files, 0, callback);
  },

  createUser: function() {
    return axios
      .post(this.apiBasePath() + "/auth/local/register", {
        username: "prova",
        email: "test@yopmail.com",
        password: "provaPassword",
      },{
        headers: {
          Authorization: `Bearer ${bearer}`
        }
      })
      .then((res) => console.log("res: ", res))
      .catch(function(error) {
        console.log(error);
      });
  },

  getUsers: function() {
    return axios.get(this.apiBasePath() + "/users",{
      headers: {
        Authorization: `Bearer ${bearer}`
      }
    }).then((res) => {
      console.log(res);
    });
  },

  checkCF: function(idForm, cf) {
    return axios
      .get(this.apiBasePath() + "/forms/" + idForm + "/checkCF/" + cf, {
        headers: {
          Authorization: `Bearer ${bearer}`
        }
      })
      .then((res) => {
        return res;
      });
  },
};

export default DataService;
