import { useNavigate } from "react-router-dom";
import { FullButton } from "./full-button";

export function DetailsBandoComponent({ domandeForm, nextArea, currentCode }) {
  const navigate = useNavigate();

  const navigateToFormBando = () => {
    navigate("/form-bando?code=" + currentCode);
  };

  return (
    <>
      <div className="font-arial text-midnight-color">
        <div className="pt-12 text-lg font-bold leading-6">
          <p className="">{domandeForm?.description}</p>
        </div>

        <div className="pt-10">
          <div
            className={`md:grid lg:grid md:grid-cols-2 lg:grid-cols-2 text-sm`}
          >
            <div className="flex">
              <label className="font-bold w-[168px]">Codice bando: </label>
              <label className="w-[216px]">{domandeForm?.code}</label>
            </div>

            <div className="flex md:pl-6 lg:pl-6 pt-4-mobile">
              <label className="font-bold w-[168px]">Stato bando </label>
              <label className="w-[216px]">{domandeForm?.status}</label>
            </div>
          </div>

          <div
            className={`pt-4 md:grid lg:grid md:grid-cols-2 lg:grid-cols-2 text-sm`}
          >
            <div className="flex">
              <label className="font-bold w-[168px]">Numero posti </label>
              <label className="w-[216px]">{domandeForm?.places}</label>
            </div>
          </div>

          <div
            className={`pt-6 md:grid lg:grid md:grid-cols-2 lg:grid-cols-2 text-sm`}
          >
            <div className="flex">
              <label className="font-bold w-[168px]">Contratto </label>
              <label>{domandeForm?.contract_type}</label>
            </div>

            <div className="flex pt-4-mobile md:pl-6 lg:pl-6">
              <label className="font-bold w-[168px]">Ruolo</label>
              <label className="w-[216px]">{domandeForm?.professional}</label>
            </div>
          </div>

          <div
            className={`pt-6 md:grid lg:grid md:grid-cols-2 lg:grid-cols-2 text-sm`}
          >
            <div className="flex">
              <label className="font-bold w-[168px]">
                Data apertura bando{" "}
              </label>
              <label className="w-[216px]">{domandeForm?.start}</label>
            </div>

            <div className="flex pt-4-mobile md:pl-6 lg:pl-6">
              <label className="font-bold w-[168px]">
                Data chiusura bando{" "}
              </label>
              <label className="w-[216px]">{domandeForm?.end}</label>
            </div>
          </div>
        </div>

        <div className="pt-10">
          <div className={`grid grid-cols-2 text-sm`}>
            <div className="flex col-span-2">
              <label className="font-bold w-[90px]">
                Requisiti generali e specifici di ammissione{" "}
              </label>
              <label
                className="md:pl-[76px] lg:pl-[76px] pl-[50px] w-[942px]"
                dangerouslySetInnerHTML={{ __html: domandeForm?.requirements }}
              />
            </div>
          </div>

          {domandeForm?.pre_selective && (
            <div className={`pt-6 grid grid-cols-2 text-sm`}>
              <div className="flex col-span-2">
                <label className="font-bold w-[90px]">
                  Prova pre-selettiva{" "}
                </label>
                <label
                  className="pl-[76px] w-[942px]"
                  dangerouslySetInnerHTML={{
                    __html: domandeForm?.pre_selective,
                  }}
                />
              </div>
            </div>
          )}

          {domandeForm?.selective && (
            <div className={`pt-6 grid grid-cols-2 text-sm`}>
              <div className="flex col-span-2">
                <label className="font-bold w-[90px]">Prova selettiva </label>
                <label
                  className="pl-[76px] w-[942px]"
                  dangerouslySetInnerHTML={{ __html: domandeForm?.selective }}
                />
              </div>
            </div>
          )}

          <div className={`pt-6 grid grid-cols-2 text-sm`}>
            <div className="flex col-span-2">
              <label className="font-bold w-[90px]">
                Documenti da allegare{" "}
              </label>
              <label
                className="md:pl-[76px] lg:pl-[76px] pl-[60px] w-[942px]"
                dangerouslySetInnerHTML={{
                  __html: domandeForm?.required_documents,
                }}
              />
            </div>
          </div>

          <div className={`pt-6 grid grid-cols-2 text-sm`}>
            <div className="flex col-span-2">
              <label className="font-bold w-[90px]">Per candidarsi </label>
              <label className="md:ml-[76px] lg:ml-[50px] ml-[52px] cursor-pointer underline">
                <FullButton
                  disabled={domandeForm?.status === "chiuso"}
                  buttonText={domandeForm?.candidate_requirements}
                  methodToCall={navigateToFormBando}
                ></FullButton>
              </label>
            </div>
          </div>

          {domandeForm?.attachments && (
            <div className={`pt-6 grid grid-cols-2 text-sm`}>
              <div className="flex col-span-2">
                <label className="font-bold w-[90px]">Scarica bando </label>
                <div className="md:ml-[76px] lg:ml-[76px] ml-[41px] flex underline">
                  <a
                    className="flex"
                    href={domandeForm?.attachments[0]?.url}
                    download={domandeForm?.attachments[0]?.name}
                  >
                    <label className="my-auto">
                      {domandeForm?.attachments[0]?.name}{" "}
                    </label>
                    <div className="my-auto">
                      <img
                        src={require("../img/ICN_download.png")}
                        className="pl-[5px] my-auto mx-auto object-fit"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="md:flex lg:flex md:justify-between lg:justify-between pt-10 text-xs md:text-sm lg:text-sm">
        <div className="flex-start">
          Ultimo aggiornamento: {domandeForm?.updatedAt}
        </div>
        <div className="mt-4-mobile flex-end">Emesso da: EAV</div>
      </div>
    </>
  );
}
