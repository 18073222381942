export function SubGridRiepilogo({ field }) {
  return (
    <>
      {field.related.subtitle && (
        <div className="pt-3">
          <div className={` text-lg italic`}>{field.related.subtitle}</div>
        </div>
      )}

      <div className={``}>
        {field.related.map((fieldBlockRel, fieldBlockRelIndex) => (
          <div className="" key={fieldBlockRelIndex}>
            {fieldBlockRel?.subtitle && (
              <div className="italic pt-3">{fieldBlockRel?.subtitle}</div>
            )}

            <div className="grid md:grid-cols-2 lg:grid-cols-2 text-lg">
              {fieldBlockRel.fields.map((fieldRel, fieldIndexRel) => {
                if (!fieldRel.value || fieldRel.value === "" || fieldRel.value === null || fieldRel.value === undefined || fieldRel?.value?.length === 0) {
                  return <></>;
                }

                return (
                  <div key={fieldIndexRel} className="">
                    {fieldRel.type != "radio" && fieldRel.type != "checkbox" && (
                      <div className={`pt-3 flex overflow-hidden`}>
                        <div className="w-[100px] md:w-[290px] lg:w-[290px]">
                          <label className={`font-bold`}>
                            {fieldRel.label}
                            {fieldRel.required ? "*" : ""}
                          </label>
                        </div>
                        <div className="">
                          {fieldRel.type != "file" && (
                            <label className={``}>{fieldRel.value}</label>
                          )}

                          {fieldRel.type === "file" && fieldRel.value && (
                            <div>
                              {fieldRel.value.map((value, valueIndex) => {
                                return (
                                  <div key={valueIndex}>
                                    <label key={valueIndex} className={``}>
                                      {value}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {fieldRel.type === "checkbox" && (
                      <div className="pt-6 flex col-span-2">
                        {fieldRel.value && (
                          <input
                            type="checkbox"
                            checked
                            disabled
                            className="w-[18px] h-[18px] flex-none"
                          />
                        )}

                        {!fieldRel.value && (
                          <input
                            type="checkbox"
                            disabled
                            className="w-[18px] h-[18px] flex-none"
                          />
                        )}

                        <p className="pl-4">
                          {fieldRel.label}
                          {fieldRel.required ? "*" : ""}
                        </p>
                      </div>
                    )}

                    {fieldRel.type === "radio" && (
                      <div className="pt-6 flex col-span-2">
                        {fieldRel.value && (
                          <input type="radio" checked disabled />
                        )}

                        {!fieldRel.value && <input type="radio" disabled />}

                        <p className="pl-4">{fieldRel.label}</p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
