import { useEffect, useState } from "react"
import { EmptyButton } from "./empty-button";
import { FullButton } from "./full-button";

export function ModalCF({ open, onClose, nome, cognome, idCandidatura, codiceCandidatura }) {

    const [check, setCheck] = useState(false);

    useEffect(() => {
        console.log("Modal chiamato!!!");
        if(nome && cognome){
            setCheck(true);
        } else {
            setCheck(false);
        }
    }, [nome, cognome])

    return (
        <div onClick={onClose} className={`fixed inset-0 flex justify-center items-center transition-colors ${open ? "visible bg-black/20" : "invisible"}`}>
            <div className={`bg-white rounded-xl shadow p-6 transition-all w-2/3 ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}`} onClick={e => e.stopPropagation()}>

                <div className="flex justify-center items-center"> {/* HEADER */}
                    <label className={`text-midnight-color font-arial font-bold text-center text-2xl`}>
                        ATTENZIONE
                    </label>
                </div>


                <div className="flex justify-center items-center pt-6"> {/* BODY */}

                    {/* <label>{nome} - {cognome} - {codiceCandidatura}</label> */}

                    {check === true  && (
                    <label className={`text-midnight-color font-arial font-bold text-center`} dangerouslySetInnerHTML={{ __html: "CANDIDATURA GIA’ INOLTRATA PER QUESTO BANDO DI SELEZIONE.<br /> <br />(è ammessa una sola candidatura)"}} />
                    )}

                    {check === false && (
                        <label className={`text-midnight-color font-arial font-bold text-center`} dangerouslySetInnerHTML={{ __html: "CANDIDATURA GIA’ INOLTRATA PER QUESTO BANDO DI SELEZIONE.<br /> <br />(è ammessa una sola candidatura)" }} />
                    )} 

                    {/* <label className={`text-midnight-color font-arial font-bold text-center`} dangerouslySetInnerHTML={{ __html: "CANDIDATURA GIA' INOLTRATA.<br />Qualora si rendano necessarie modifiche o integrazioni, il candidato DEVE inviare una PEC al SEGUENTE indirizzo enteautonomovolturno@legalmail.it<mailto: enteautonomovolturno@legalmail.it> oggetto DELLA PEC: <br/> Concorso " + codiceCandidatura + " - Codice univoco candidatura - " + cognome + " - " + nome }} /> */}


                </div>

                <div className={`flex items-center justify-center mt-12 pb-8`}> {/* FOOTER */}
                    <FullButton buttonText="Conferma e procedi" methodToCall={onClose} />
                </div>
            </div>
        </div>
    )
}