import { useState } from 'react';

export function FileInput({ labelText, placeholderText, name, register, inputRef, isRequired, errors, errorMsg, onClick, onChange, index, id, isInSubGrid, valueOfRel, col_rel, ...rest }) {

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    let labelId = id + "placeholder";

    return (
        <>
            <div className={`${index != 0 ? "mt-[32px]" : ""} lg:col-span-4`}> {/* ${isInSubGrid ? "mt-4" : "mt-8"} */}
                <div className="my-auto flex-col">
                    <div className={`flex ${valueOfRel === false ? "text-disabled-color pointer-events: none" : ""}`}>
                        {labelText}{isRequired ? "*" : ""}
                        <div className="my-auto flex">
                            <img src={require('../img/ICN_info.png')} className="pl-[5px] my-auto mx-auto object-fit" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}/>
                            {isHovering && (
                                <div>
                                    <label className='pl-4 text-midnight-color text-sm'>Tenere premuto il tasto "CTRL" sulla propria tastiera e selezionare i file interessati.</label>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="lg:flex pt-3">
                        <input type="file" id={id} accept="application/pdf" disabled={valueOfRel != undefined && valueOfRel === false} placeholder="File PDF" ref={inputRef} name={name} onChange={onChange} multiple={true} className={`pointer-events-none border-[1px] rounded-md p-4 md:min-w[236px] lg:min-w[236px] lg:w-[568px] lg:h-[48px] col-span-2 text-xs inputField-smallScreens ${errors[name] && isRequired ? "border-error-color placeholder-error-color text-error-color" : ""}`}></input>
                        <button type="button" htmlFor="file" disabled={valueOfRel != undefined && valueOfRel === false} onClick={onClick} className="md:ml-4 lg:ml-6 md:mt-4 mt-4-mobile w-24 h-8 lg:w-40 lg:h-10 bg-midnight-color text-white rounded-md font-bold text-base my-auto lg:relative lg:bottom-3">Sfoglia...</button>
                        <div className={`relative bottom-[82px] md:bottom-[35px] lg:top-4 w-[200px] ${isInSubGrid ? "lg:right-[680px]" : "lg:right-[730px] right-[-15px] "}`}>
                            <div id={labelId} className={`text-disabled-midnight-color fileInput-placeholder`}>Carica file</div>
                        </div>
                    </div>
                    {errors[name] && isRequired ? <div className="mt-1.5 font-bold text-xs" data-required="true">Campo Obbligatorio</div> : <></>}
                </div>
            </div>
        </>
    )

}