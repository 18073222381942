import { useEffect, useRef, useState } from "react";
import { useForm, FormState } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { format } from "date-fns";
import Form from "../components/Form";
import { LabeledTextInput } from "../components/labeledInput";
import { CheckboxComponent } from "../components/checkbox";
import { DateInput } from "../components/dateInput";
import { EmailInput } from "../components/emailInput";
import { NumberInput } from "../components/numberInput";
import { AreaRoundedFull } from "../components/area-roundedfull";
import { EmptyButton } from "../components/empty-button";
import { FullButton } from "../components/full-button";
import { ChoiceRadioButton } from "../components/yes-no-radiobutton";
import { FileInput } from "../components/fileInput";
import { SelectInput } from "../components/selectInput";
import { RadioGridComponent } from "../components/radioGrid";
import DataService from "../services/DataService";
import { SubGridChecker } from "../components/subGridChecker";
import { SubGridRiepilogo } from "../components/subGridRiepilogo";
import { ThankYouComponent } from "../components/thankyouComponent";
import {
  DetailsBando,
  DetailsBandoComponent,
} from "../components/detailsBandoComponent";
import UtilityService from "../services/UtilityService";

export default function DettaglioBando() {
  const form = useForm();
  const {
    register,
    handleSubmit,
    control,
    formState,
    getValues,
    setValue,
    watch,
    setError,
  } = form;
  const { errors } = formState;

  const [area, setArea] = useState(-1);
  const [numberOfFiles, setNumberOfFiles] = useState(0);
  const [domandeForm, setDomandeForm] = useState();
  const [currentCode, setCurrentCode] = useState();

  useEffect(() => {
    const code = UtilityService.getUrlParams("code");
    setCurrentCode(code);

    DataService.getFormDettaglio(setDomandeForm, modifyJson, code).then(() => {
      window.scrollTo({ top: 0, left: 0 });
    });
  }, []);

  const modifyJson = (jsonToModify) => {
    jsonToModify.start = formatDateField(jsonToModify.start);

    jsonToModify.end = formatDateField(jsonToModify.end);

    jsonToModify.updatedAt = formatDateField(jsonToModify.updatedAt);
  };

  const formatDateCreatedTime = (date) => {
    let formattedDate = format(new Date(date), "HH.mm");
    return formattedDate;
  };

  const formatDateField = (date) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const goBackArea = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setArea(area - 1);
  };

  const backToBandi = () => {
    alert("Ritorno alla pagina dei bandi...");
  };

  return (
    <div className="h-full bg-gray-background">
      <div>
        <img
          src={require("../img/EAV-logo.png")}
          className="pl-12 pt-12 w-8/12 h-8/12 lg:w-2/5 lg:h-2/5"
        />
      </div>
      <hr className="mt-12"></hr>
      <div class="flex pl-2 pr-2 text-[9px] lg:pl-[104px] pt-6 lg:text-xs font-arial font-normal break-words">
        <span className="text-disabled-color">Candidature /</span>
        <span className="font-arial font-normal text-midnight-color pl-1">
          {domandeForm?.title}
        </span>
      </div>
      <div className="md:h-7 lg:h-7 mt-8 text-midnight-color font-arial text-xl md:text-2xl lg:text-2xl font-bold break-words text-center">
        {domandeForm?.title}
      </div>
      <div className="ml-4 mr-4 lg:ml-[104px] lg:mr-[104px] min-h-[520px] mt-8 bg-white rounded-s-md shadow-md font-arial break-words pb-16">
        {" "}
        {/*Inizio della card*/}
        <div className="pl-10 pr-10">
          <DetailsBandoComponent
            domandeForm={domandeForm}
            currentCode={currentCode}
          />
        </div>
      </div>{" "}
      {/*Fine della card*/}
      <div className="pt-16 pb-16"></div>
    </div>
  );
}
