import { useEffect, useState } from 'react'


export function AreaRoundedFull({ areaNumber, areaName, currentArea }) {

  const [showAreaName, setShowAreaName] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setShowAreaName(false);
      } else {
        setShowAreaName(true);
      }
    };
  });

  if (areaNumber > 0) {
    return (
      <>
        <hr className="ml-4 lg:w-5 bg-red-500 border-[1px] my-auto" />
        <div className={`ml-4 bg-disabled-color-2 rounded-full grow-0 shrink-0 w-8 h-8 text-white text-center pt-[9px] text-[10px] lg:text-xs ${currentArea === areaNumber ? 'bg-midnight-color' : 'bg-disabled-color-2'}`}>{areaNumber + 1}</div> {/*Rounded-full 2*/}
        <span className={`hidden md:block lg:block pl-2 my-auto text-disabled-color-2 font-bold text-xs lg:text-sm ${currentArea === areaNumber ? 'text-midnight-color' : 'text-disabled-color-2'}`}>{areaName}</span>
      </>
    )
  } else {
    return (
      <>
        <div className={`rounded-full grow-0 shrink-0 w-8 h-8 text-white text-center pt-[9px] lg:text-sm text-xs ${currentArea === areaNumber ? 'bg-midnight-color' : 'bg-disabled-color-2'}`}>{areaNumber + 1}</div> {/*Rounded-full 1*/}
        <span className={`pl-2 hidden md:block lg:block my-auto text-disabled-color-2 font-bold text-xs lg:text-sm ${currentArea === areaNumber ? 'text-midnight-color' : 'text-disabled-color-2'}`}>{areaName}</span>
      </>
    )
  }
}
