import { LabeledTextInput } from '../components/labeledInput';
import { CheckboxComponent } from '../components/checkbox';
import { DateInput } from '../components/dateInput';
import { EmailInput } from '../components/emailInput';
import { NumberInput } from '../components/numberInput';
import { ChoiceRadioButton } from '../components/yes-no-radiobutton';
import { FileInput } from '../components/fileInput';
import { SelectInput } from '../components/selectInput';

export function SubGridChecker({ questionAnswer, register, errors, pdfInputChange, pdfInputClick, pdfAddToRef, fieldIndex, valueOfRel, userVote, setUserVote, condition, form, checkOnPreciseValue }) {
    return (
        <div className="col-span-4">

            <div className="">
                {questionAnswer?.related.filter(
                    rel => {
                        if(checkOnPreciseValue){
                            return rel.eq === valueOfRel
                        }else{
                            return true
                        }
                    }
                ).map((questionRel, relIndex) => (

                    <div key={relIndex}>
                        <div className={`${questionRel.title || questionRel.subtitle ? "pt-6" : ""} ${!valueOfRel ? "text-disabled-color" : ""}`}>
                            {questionRel?.title && (
                                <div className="font-bold">{questionRel?.title}</div>
                            )}

                            {questionRel?.subtitle && (
                                <div className="">{questionRel?.subtitle}</div>
                            )}
                        </div>

                        <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-x-4 text-sm w-100px-mobile"
                            style={{
                                alignItems: "flex-end",
                            }}
                        key={relIndex}>

                            {
                                questionRel.fields.map((questionFieldRel, fieldIndexRel) => {
                                    if (questionFieldRel.type === "text") {
                                        return (
                                            <div key={fieldIndexRel}>
                                                <LabeledTextInput
                                                    key={questionFieldRel.slug}
                                                    labelText={questionFieldRel.label}
                                                    placeholderText={questionFieldRel.label}
                                                    name={questionFieldRel.slug}
                                                    register={register}
                                                    isRequired={questionFieldRel.required}
                                                    minLength={questionFieldRel.minlength}
                                                    maxLength={questionFieldRel.maxlength}
                                                    errors={errors}
                                                    errorMsg="Campo obbligatorio"
                                                    titleExists={false}
                                                    value={questionFieldRel.value}
                                                    valueOfRel={valueOfRel}
                                                    setUserVoteFalse={() => setUserVote(false)}
                                                    setUserVoteTrue={() => setUserVote(true)}
                                                    form={form}
                                                //onChange={(e) => setFormValuesStorage(questionFieldRel, e.target.value)}
                                                />
                                            </div>
                                        )
                                    }

                                    if (questionFieldRel.type === "checkbox") {
                                        return (
                                            <div key={fieldIndexRel}>
                                                <CheckboxComponent
                                                    key={questionFieldRel.slug}
                                                    name={questionFieldRel.slug}
                                                    labelText={questionFieldRel.label}
                                                    register={register}
                                                    isRequired={questionFieldRel.required}
                                                    errors={errors}
                                                    errorMsg="Campo obbligatorio"
                                                    index={fieldIndex}
                                                    valueOfRel={valueOfRel}
                                                //onChange={(e) => setFormValuesStorage(questionFieldRel, e.target.value)}
                                                />

                                            </div>

                                        )
                                    }

                                    if (questionFieldRel.type === "date") {
                                        return (
                                            <div key={fieldIndexRel}>
                                                <DateInput
                                                    key={questionFieldRel.slug}
                                                    name={questionFieldRel.slug}
                                                    labelText={questionFieldRel.label}
                                                    placeholderText={questionFieldRel.label}
                                                    register={register}
                                                    isRequired={questionFieldRel.required}
                                                    errors={errors}
                                                    valueOfRel={valueOfRel}
                                                    errorMsg="Campo obbligatorio"
                                                //onChange={(e) => setFormValuesStorage(questionFieldRel, e.target.value)}
                                                />
                                            </div>
                                        )
                                    }

                                    if (questionFieldRel.type === "email") {
                                        return (
                                            <div key={fieldIndexRel}>
                                                <EmailInput
                                                    key={questionFieldRel.slug}
                                                    name={questionFieldRel.slug}
                                                    labelText={questionFieldRel.label}
                                                    placeholderText={questionFieldRel.label}
                                                    register={register}
                                                    isRequired={questionFieldRel.required}
                                                    errors={errors}
                                                    errorMsg="Campo obbligatorio"
                                                    minLength={questionFieldRel.minlength}
                                                    maxLength={questionFieldRel.maxlength}
                                                    valueOfRel={valueOfRel}
                                                // onChange={(e) => setFormValuesStorage(questionFieldRel, e.target.value)}
                                                />
                                            </div>
                                        )
                                    }

                                    if (questionFieldRel.type === "number") {
                                        return (
                                            <div key={fieldIndexRel}>
                                                <NumberInput
                                                    key={questionFieldRel.slug}
                                                    name={questionFieldRel.slug}
                                                    labelText={questionFieldRel.label}
                                                    placeholderText={questionFieldRel.label}
                                                    register={register}
                                                    isRequired={questionFieldRel.required}
                                                    min={questionFieldRel.min}
                                                    max={questionFieldRel.max}
                                                    errors={errors}
                                                    errorMsg="Campo obbligatorio"
                                                    valueOfRel={valueOfRel}
                                                //onChange={(e) => setFormValuesStorage(questionFieldRel, e.target.value)}
                                                />
                                            </div>
                                        )
                                    }

                                    if (questionFieldRel.type === "radio") {
                                        return (
                                            <div key={fieldIndexRel}>
                                                <ChoiceRadioButton
                                                    key={questionFieldRel.slug}
                                                    name={questionFieldRel.slug}
                                                    labelText={questionFieldRel.label}
                                                    placeholderText={questionFieldRel.label}
                                                    register={register}
                                                    isRequired={questionFieldRel.required}
                                                    errors={errors}
                                                    errorMsg="Campo obbligatorio"
                                                    valueOfRel={valueOfRel}
                                                //onChange={(e) => setFormValuesStorage(questionFieldRel, e.target.value)}
                                                />
                                            </div>
                                        )
                                    }

                                    if (questionFieldRel.type === "file") {
                                        return (
                                            <>
                                                
                                                <div key={fieldIndexRel} className='md:col-span-3 lg:col-span-3' style={{
                                                    display: 'contents'
                                                }}>
                                                    <FileInput
                                                        key={questionFieldRel.slug}
                                                        name={questionFieldRel.slug}
                                                        labelText={questionFieldRel.label}
                                                        placeholderText={questionFieldRel.labelText}
                                                        inputRef={(el) => pdfAddToRef(el)}
                                                        isRequired={questionFieldRel.required}
                                                        errors={errors}
                                                        errorMsg="Campo obbligatorio"
                                                        onClick={() => pdfInputClick(fieldIndexRel, questionFieldRel.id)}
                                                        onChange={(e) => pdfInputChange(e, fieldIndexRel, questionFieldRel.id, questionAnswer.related.id)}
                                                        index={fieldIndex}
                                                        id={questionFieldRel.id}
                                                        valueOfRel={valueOfRel}
                                                        isInSubGrid={true}
                                                    />
                                                </div>
                                            </>
                                        )
                                    }

                                    if (questionFieldRel.type === "select") {
                                        return (
                                            <div key={fieldIndexRel}>
                                                <SelectInput
                                                    key={questionFieldRel.slug}
                                                    name={questionFieldRel.slug}
                                                    labelText={questionFieldRel.label}
                                                    placeholderText={questionFieldRel.label}
                                                    options={questionFieldRel.options}
                                                    register={register}
                                                    isRequired={questionFieldRel.required}
                                                    errors={errors}
                                                    errorMsg="Campo obbligatorio"
                                                    valueOfRel={valueOfRel}
                                                //onChange={(e) => setFormValuesStorage(questionFieldRel, e.target.value)}
                                                />
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>

                    </div>
                ))}
            </div>


        </div>
    )
}