import { useEffect, useRef, useState } from "react";
import { useForm, FormState } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { format } from "date-fns";
import Form from "../components/Form";
import { LabeledTextInput } from "../components/labeledInput";
import { CheckboxComponent } from "../components/checkbox";
import { DateInput } from "../components/dateInput";
import { EmailInput } from "../components/emailInput";
import { NumberInput } from "../components/numberInput";
import { AreaRoundedFull } from "../components/area-roundedfull";
import { EmptyButton } from "../components/empty-button";
import { FullButton } from "../components/full-button";
import { ChoiceRadioButton } from "../components/yes-no-radiobutton";
import { FileInput } from "../components/fileInput";
import { SelectInput } from "../components/selectInput";
import { RadioGridComponent } from "../components/radioGrid";
import DataService from "../services/DataService";
import UtilityService from "../services/UtilityService";
import { SubGridChecker } from "../components/subGridChecker";
import { SubGridRiepilogo } from "../components/subGridRiepilogo";
import { ThankYouComponent } from "../components/thankyouComponent";
import {
  DetailsBando,
  DetailsBandoComponent,
} from "../components/detailsBandoComponent";
import { Modal } from "../components/modal";
import { ModalCF } from "../components/modalCF";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import moment from "moment";

export default function FormBando() {
  const form = useForm();
  const {
    register,
    handleSubmit,
    control,
    formState,
    getValues,
    setValue,
    watch,
    setError,
  } = form;
  const { errors } = formState;

  const [area, setArea] = useState(0);
  const [numberOfFiles, setNumberOfFiles] = useState(0);
  const [pdfFiles, setPdfFiles] = useState(Array(numberOfFiles).fill(null));
  const [domandeForm, setDomandeForm] = useState();

  const [userAge, setUserAge] = useState();
  const [oldDate, setOldDate] = useState();
  const [modalCFtrigger, setModalCFtrigger] = useState(false);
  const [cfCheck, setCfCheck] = useState(false);
  const [idCandidaturaCFcheck, setIdCandidaturaCFcheck] = useState(0);
  const [nomeCFcheck, setNomeCFcheck] = useState("");
  const [cognomeCFcheck, setCognomeCFcheck] = useState("");

  const [userVote, setUserVote] = useState();

  const [buttonsError, setButtonsError] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCFOpen, setIsModalCFOpen] = useState(false);

  const inputRefUpload = useRef([]);

  const relatedToWatch = watch();

  const [isBandoInterno, setIsBandoInterno] = useState(false);

  useEffect(() => {
    if (domandeForm?.status === "chiuso") {
      const code = UtilityService.getUrlParams("code");
      window.location.href = "/dettagli-bando?code=" + code;
    }
    console.log("domandeForm", domandeForm);
  }, [domandeForm]);

  useEffect(() => {
    const code = UtilityService.getUrlParams("code");

    DataService.getFormBando(
      setDomandeForm,
      setIsBandoInterno,
      modifyJson,
      code
    ).then(() => {
      window.scrollTo({ top: 0, left: 0 });
      checkNumberOfFileInputs();
      setPdfFiles(Array(numberOfFiles).fill(null));
      console.log("pdf files length: " + pdfFiles.length);
    });
    checkForLocalStorageForm();
  }, []);

  useEffect(() => {
    console.log("USER VOTE: ", userVote);
  }, [userVote]);

  const getUser = () => {
    DataService.getUsers().then(() => {
      console.log("USER POST FATTA!");
    });
  };

  useEffect(() => {
    console.log("domandeForm: ", domandeForm);
    // if (domandeForm.internal) {
    //     setIsBandoInterno(true);
    // }
  }, [domandeForm]);

  useEffect(() => {
    console.log("BANDO INTERNO: ", isBandoInterno);
  }, [isBandoInterno]);

  const checkForLocalStorageForm = () => {
    console.log(localStorage);
    let data = UtilityService.getLocalStorage();

    if (data) {
      Object.keys(data).forEach((field) => {
        console.log("Settando il value.");
        setValue(field, data[field]);
      });
    }
  };

  const setFormValuesStorage = (data, e) => {
    console.log("Setting values!");

    if (data.type != "checkbox") {
      setValue(data.slug, e);
    }

    let formObj = getValues();
    console.log("FORM: ", formObj);
    UtilityService.setLocalStorage(formObj);
  };

  const checkRelFileInput = (questionAnswer) => {
    console.log("ON CHANGE DEL RADIO!!");
    if (questionAnswer.related) {
      questionAnswer.related.map((questionBlock, blockIndex) => {
        questionBlock.fields.map((questionField, fieldIndex) => {
          if (questionField.type === "file") {
            let fileField = document.getElementById(questionField.id);
            console.log("file field : ", fileField);
            let fileLabelId = questionField.id + "placeholder";
            console.log("filelabelid : ", fileLabelId);
            let fileLabel = document.getElementById(fileLabelId);
            console.log("filelabel: ", fileLabel);
            if (
              !CheckRelValue(
                getValues(questionAnswer.slug),
                questionAnswer.related.if,
                questionAnswer
              )
            ) {
              fileLabel.innerText = "Carica file";
              fileField = null;
            }
          }
        });
      });
    }
  };

  const checkButtonsError = () => {
    let shouldContinue = true;

    console.log("checkbuttonserror chiamato");
    domandeForm.areas[area].blocks.map((questionBlock, blockIndex) => {
      questionBlock.fields.map((questionField, fieldIndex) => {
        if (questionField.type === "checkbox" && questionField.required) {
          console.log("slug: ", questionField.slug);
          console.log("id: ", questionField.id);
          let buttonField = document.getElementById(questionField.id);
          console.log(
            "buttonField: ",
            document.getElementById(questionField.id)
          );
          console.log("buttonField value: ", buttonField.value);
          if (questionField.value === "off" && questionField.required) {
            buttonField.style.border = "1px solid #E54E4B";
            buttonField.style.color = "#E54E4B";
            buttonField.style.placeholder = "color: #E54E4B";
            shouldContinue = false;
          } else {
            questionField.value = "on";
            buttonField.style.border = "1px solid #0FA0D3";
            buttonField.style.color = "#0FA0D3";
            buttonField.style.placeholder = "color: #0FA0D3";
          }
        }
      });
    });

    if (shouldContinue) {
      console.log("Tutto ok!");
      return false;
    } else {
      return true;
    }
  };

  const checkRadioError = () => {
    let shouldContinue = true;

    domandeForm.areas[area].blocks.map((questionBlock, blockIndex) => {
      questionBlock.fields.map((questionField, fieldIndex) => {
        if (
          questionField.type === "radio" &&
          questionField.requiredValue != null &&
          questionField.requiredValue != undefined
        ) {
          let radioField = document.getElementById(questionField.id);
          if (questionField.requiredValue != getValues(questionField.slug)) {
            shouldContinue = false;
            let nameFileInputId = questionField.id + "error";
            console.log(nameFileInputId);
            let tempFileInputName = document.getElementById(nameFileInputId);
            console.log(tempFileInputName);
            tempFileInputName.innerText = "Campo Obbligatorio";
          } else {
            let nameFileInputId = questionField.id + "error";
            let tempFileInputName = document.getElementById(nameFileInputId);
            tempFileInputName.innerText = "";
          }
        }

        if (questionField.related) {
          questionField.related.map((questionBlockRel, blockRelIndex) => {
            questionBlockRel.fields.map((questionFieldRel, fieldRelIndex) => {
              if (
                questionFieldRel.type === "radio" &&
                questionFieldRel.requiredValue != null &&
                questionFieldRel.requiredValue != undefined
              ) {
                if (
                  questionFieldRel.requiredValue !=
                  getValues(questionFieldRel.slug)
                ) {
                  shouldContinue = false;
                  let nameFileInputId = questionFieldRel.id + "error";
                  let tempFileInputName = document.getElementById(
                    nameFileInputId
                  );
                  tempFileInputName.innerText = "Campo Obbligatorio";
                } else {
                  let nameFileInputId = questionFieldRel.id + "error";
                  let tempFileInputName = document.getElementById(
                    nameFileInputId
                  );
                  tempFileInputName.innerText = "";
                }
              }
            });
          });
        }
      });
    });

    if (shouldContinue) {
      console.log("Tutto ok!");
      return false;
    } else {
      return true;
    }
  };

  const checkFileError = () => {
    let shouldContinue = true;

    domandeForm.areas[area].blocks.map((questionBlock, blockIndex) => {
      questionBlock.fields.map((questionField, fieldIndex) => {
        if (questionField.type === "file" && questionField.required) {
          let fileField = document.getElementById(questionField.id);
          if (fileField.files.length === 0) {
            console.log("fileField.files.length === 0");
            fileField.style.border = "1px solid #E54E4B";
            fileField.style.color = "#E54E4B";
            fileField.style.placeholder = "color: #E54E4B";
            let nameFileInputId = questionField.id + "placeholder";
            let tempFileInputName = document.getElementById(nameFileInputId);
            tempFileInputName.innerText = "";
            shouldContinue = false;
          } else {
            fileField.style.border = "1px solid #DEE4EB";
            fileField.style.color = "#204070";
            fileField.style.placeholder = "color: #204070";
          }
        }

        if (questionField.related) {
          questionField.related.map((questionBlockRel, blockRelIndex) => {
            questionBlockRel.fields.map((questionFieldRel, fieldRelIndex) => {
              console.log("IF 1: ", questionFieldRel.type === "file");
              console.log("IF 2: ", questionFieldRel.required === true);
              console.log(
                "IF 3: ",
                CheckRelValue(
                  getValues(questionField.slug),
                  questionBlockRel.if,
                  questionField
                )
              );
              console.log("QUESTION BLOCK REL: ", questionBlockRel);
              console.log("QUESTION BLOCK REL.IF: ", questionBlockRel.if);
              console.log(
                "QUESTION FIELD.VALUE: ",
                getValues(questionField.slug)
              );
              if (
                questionFieldRel.type === "file" &&
                questionFieldRel.required &&
                CheckRelValue(
                  getValues(questionField.slug),
                  questionBlockRel.if,
                  questionField
                )
              ) {
                let fileFieldRel = document.getElementById(questionFieldRel.id);

                console.log(
                  "questionFieldRel",
                  questionFieldRel,
                  "fileFieldRel",
                  fileFieldRel
                );

                if (fileFieldRel?.files?.length === 0) {
                  console.log("fileFieldRel.files.length === 0");
                  fileFieldRel.style.border = "1px solid #E54E4B";
                  fileFieldRel.style.color = "#E54E4B";
                  fileFieldRel.style.placeholder = "color: #E54E4B";
                  let nameFileInputId = questionFieldRel.id + "placeholder";
                  let tempFileInputName = document.getElementById(
                    nameFileInputId
                  );
                  tempFileInputName.innerText = "";
                  shouldContinue = false;
                } else {
                  try {
                    fileFieldRel.style.border = "1px solid #DEE4EB";
                    fileFieldRel.style.color = "#204070";
                    fileFieldRel.style.placeholder = "color: #204070";
                  } catch (err) {}
                }
              }

              // pdfFiles.map((singleFile, fileIndex) => {
              //     if (singleFile.field === questionFieldRel.id) {
              //         pdfFiles.splice(fileIndex, 1);
              //         console.log("NEW PDFFILES ARRAY: ", pdfFiles);
              //     }
              // })
            });
          });
        }
      });
    });

    if (shouldContinue) {
      console.log("Tutto ok!");
      return false;
    } else {
      return true;
    }
  };

  const reassignInputFiles = (areaToCheck) => {
    domandeForm.areas[areaToCheck].blocks.map((questionBlock, blockIndex) => {
      questionBlock.fields.map((questionField, fieldIndex) => {
        if (questionField.type === "file") {
          let counter = 0;
          let fileName = "";
          for (let i = 0; i < pdfFiles.length; i++) {
            if (pdfFiles[i].field === questionField.id) {
              fileName = pdfFiles[i].file.name;
              counter++;
            }
          }

          let inputField = document.getElementsByName(questionField.slug);
          console.log(
            "DOCUMENT: " +
              JSON.stringify(document.getElementsByName(questionField.slug))
          );
          console.log("QUESTION FIELD ID: " + questionField.slug);
          console.log("INPUT FIELD: ", inputField);
          if (counter > 1) {
            if (inputField) {
              inputField.value = counter + " file selezionati";
            }
          } else {
            if (inputField) {
              inputField.value = fileName;
            }
          }
        }
      });
    });
  };

  const goBackArea = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setArea((prevArea) => {
      const newArea = prevArea - 1;
      reassignInputFiles(newArea);
      return newArea;
    });
  };

  const backToBandi = () => {
    alert("Ritorno alla pagina dei bandi...");
  };

  const logPDFFILES = () => {
    console.log("PDF FILES!!! : ", pdfFiles);
  };

  const formatDateCreatedTime = (date) => {
    let formattedDate = format(new Date(date), "HH.mm");
    return formattedDate;
  };

  const formatDateField = (date) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const modifyJson = (jsonToModify) => {
    let tempDate = jsonToModify.publishedAt;

    jsonToModify.publishedAt = formatDateField(tempDate);

    if (jsonToModify.publishedAtTime === undefined) {
      jsonToModify.publishedAtTime = formatDateCreatedTime(tempDate);
    }

    jsonToModify?.areas.map((questionArea, areaIndex) => {
      questionArea.blocks?.map((questionBlock, blockIndex) => {
        {
          questionBlock.fields.map((questionField, fieldIndex) => {
            if (
              questionField.value === undefined &&
              questionField.type != "file"
            ) {
              questionField.value = "";
            }

            if (questionField.type === "file") {
              if (questionField.value === undefined) {
                questionField.value = [];
              }

              if (questionField.path === undefined) {
                questionField.path = [];
              }
            }

            if (
              questionField.type === "checkbox" &&
              !questionField.defaultValue
            ) {
              questionField.value = "off";
            }

            if (questionField.related) {
              {
                questionField.related.map(
                  (questionBlockRel, questionBlockRelIndex) => {
                    questionBlockRel.fields.map((questionRel, relIndex) => {
                      if (
                        questionRel.value === undefined &&
                        questionRel.type != "file"
                      ) {
                        questionRel.value = "";
                      }

                      if (questionRel.type === "file") {
                        if (questionRel.value === undefined) {
                          questionRel.value = [];
                        }

                        if (questionRel.path === undefined) {
                          questionRel.path = "";
                        }
                      }
                    });
                  }
                );
              }
            }
          });
        }
      });
    });
  };

  const checkNumberOfFileInputs = () => {
    if (domandeForm && domandeForm.areas) {
      let numberOfBlocks = domandeForm.areas[area]?.blocks.length || 0;
      let fileNumber = 0;

      for (let i = 0; i < numberOfBlocks; i++) {
        let numberOfFields = domandeForm.areas[area]?.blocks[i].fields || [];
        for (let index = 0; index < numberOfFields.length; index++) {
          if (numberOfFields[index].type === "file") {
            fileNumber++;
          }

          if (numberOfFields[index].related) {
            {
              numberOfFields[index].related.map((relBlock, relBlockIndex) => {
                relBlock.fields.map((relField, relFieldIndex) => {
                  if (relField.type === "file") {
                    fileNumber++;
                  }
                });
              });
            }
          }
        }
      }
      setNumberOfFiles(fileNumber);
    }
  };

  const handleInputChange = async (e, index, fieldId, blockId) => {
    const selectedFile = e.target.files;

    if (pdfFiles) {
      for (let i = 0; i < pdfFiles.length; i++) {
        if (pdfFiles[i]?.field && pdfFiles[i]?.field === fieldId) {
          pdfFiles.splice(i, 1);
        }
      }
    }


    // for (let i = 0; i < pdfFiles.length; i++) {
    //     for (let k = 0; i < targetFiles.length; k++) {
    //         if (!pdfFiles[i].file.includes(targetFiles[k])) {
    //             pdfFiles[i].file.push(targetFiles[k]);
    //         }
    //     }
    // }

    // let pdfObject = {
    //     "path": "",
    //     "refId": blockId,
    //     "ref": domandeForm.id,
    //     "field": fieldId,
    //     "file": selectedFile
    // }

    let tempFileInput = document.getElementById(fieldId);
    if(tempFileInput){
      tempFileInput.style.color = "#204070";
    }
    let nameFileInputId = fieldId + "placeholder";
    let tempFileInputName = document.getElementById(nameFileInputId);
    tempFileInputName.innerText = "";

    let pdfObject;

    setPdfFiles((prevPdfFiles) => {
      const updatedPdfFiles = [...prevPdfFiles];

      for (let i = 0; i < selectedFile.length; i++) {
        pdfObject = {
          path: "",
          refId: blockId,
          ref: domandeForm.id,
          field: fieldId,
          file: selectedFile[i],
        };
        if (!updatedPdfFiles.includes(pdfObject)) {
          updatedPdfFiles.push(pdfObject);
        }
      }

      console.log("LENGTH OF PDF FILES ARRAY: " + updatedPdfFiles.length);
      console.log(updatedPdfFiles);
      return updatedPdfFiles;
    });
  };

  const testInputRefUpload = () => {
    console.log(inputRefUpload.current);
  };

  const handleInputClick = (index, fileInputId) => {
    console.log("index: " + index);

    inputRefUpload.current.map((currentUpload, uploadIndex) => {
      console.log("mapping...");
      console.log("Current upload.id : ", currentUpload.id);
      console.log("Fileinput id : ", fileInputId);
      if (parseInt(currentUpload.id) === fileInputId) {
        inputRefUpload.current[uploadIndex].click();
      }
    });

    // inputRefUpload.current[index].click();
  };

  const AddToRef = (el) => {
    if (el && !inputRefUpload.current.includes(el)) {
      inputRefUpload.current.push(el);
    }
  };

  const onSubmit = async (form) => {
    console.log("area: ", area);

    // let errors = checkErrors();
    // console.log("Errors in onSubmit: ", errors);

    if (area === domandeForm?.areas?.length - 1) {
      //SIAMO ALL'ULTIMA PAGINA DELL'AREA?
      //console.log(form)
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      console.log("END OF FORM!");
      compileForm();
      setArea((prevArea) => {
        const newArea = prevArea + 1;
        console.log("Domande form areas length : " + domandeForm.areas.length);
        console.log("Nuova area : " + newArea);
        return newArea;
      });
      return;
    }

    if (area === domandeForm?.areas?.length) {
      //SIAMO ALLA PAGINA DI RIEPILOGO?
      setIsModalOpen(true);
      return;
    }

    if (area < domandeForm?.areas?.length + 1) {
      //SIAMO IN UN AREA NORMALE?
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      console.log(
        "check errors () === 0 : ",
        checkErrors() === 0,
        " - errors: ",
        checkErrors()
      );
      if ((await checkErrors()) === 0) {
        setArea((prevArea) => {
          const newArea = prevArea + 1;
          console.log(
            "Domande form areas length : " + domandeForm.areas.length
          );
          console.log("Nuova area : " + newArea);
          return newArea;
        });
      }
    }
  };

  const checkErrors = async () => {
    let errors = 0;

    if (userAge && userAge < 18) {
      console.log("L'ETA' E' MINORE DI 18");
      errors++;
    }

    console.log("user vote check errors: ", userVote);

    if (userVote != undefined && userVote === false) {
      console.log("IL VOTO UTENTE RISULTA FALSO. DOVREBBE ESSERE VERO.");
      errors++;
    }

    // if (checkButtonsError()) {
    //     errors++;
    //     setButtonsError(true);
    // } else {
    //     setButtonsError(false);
    // }

    if (checkFileError()) {
      console.log("PROBLEMA SUI FILE INPUT.");
      errors++;
    }

    // try {
    //     const cfError = await checkCFerrorSubmit(); // Attendere la risoluzione della promessa
    //     if (cfError) {
    //         console.log("PROBLEMA SUL CODICE FISCALE.");
    //         setIsModalCFOpen(true);
    //         errors++;
    //     }
    //     return errors;
    // } catch (cfError) {
    //     console.error("Errore in checkCFerror:", cfError);
    //     // Gestisci l'errore in modo appropriato
    // }

    if (cfCheck != undefined && cfCheck === false) {
      console.log("PROBLEMA SUL CODICE FISCALE.");
      setIsModalCFOpen(true);
      errors++;
    }

    if (checkRadioError()) {
      console.log("PROBLEMA SUI RADIO.");
      errors++;
    }

    console.log("Errors: ", errors);
    return errors;
  };

  const checkCFerrorSubmit = () => {
    let checkCF;
    domandeForm?.areas[area]?.blocks.map((questionBlock, blockIndex) => {
      questionBlock?.fields?.map(async (questionField, fieldIndex) => {
        if (
          questionField?.slug?.includes("cf") ||
          questionField?.slug?.includes("CF") ||
          questionField?.slug?.includes("CodiceFiscale") ||
          questionField?.slug?.includes("codicefiscale") ||
          questionField?.slug?.includes("CODICEFISCALE") ||
          questionField?.slug?.includes("CodiceFiscale") ||
          questionField?.slug?.includes("codiceFiscale")
        ) {
          checkCF = await DataService.checkCF(
            domandeForm.id,
            getValues(questionField.slug)
          ).then(async (value) => {
            console.log("value status : " + value.data.status);
            if (value.data.status === true) {
              console.log("settiamo true");
              setIsModalCFOpen(true);
              return true;
            } else {
              console.log("settiamo false");
              return false;
            }

            //     console.log("VALUE CHECKCFSUBMIT: ", value.data.status);

            //     return false;
          });
        }
      });
    });
  };

  useEffect(() => {
    if (modalCFtrigger === true) {
      setCfCheck(false);
    } else {
      setCfCheck(true);
    }
  }, [modalCFtrigger]);

  const checkCFerror = () => {
    let checkCF;
    domandeForm?.areas[area]?.blocks.map((questionBlock, blockIndex) => {
      questionBlock.fields.map(async (questionField, fieldIndex) => {
        if (
          questionField.slug.includes("cf") ||
          questionField.slug.includes("CF") ||
          questionField.slug.includes("CodiceFiscale") ||
          questionField.slug.includes("codicefiscale") ||
          questionField.slug.includes("CODICEFISCALE") ||
          questionField.slug.includes("CodiceFiscale") ||
          questionField.slug.includes("codiceFiscale")
        ) {
          checkCF = await DataService.checkCF(
            domandeForm.id,
            getValues(questionField.slug)
          ).then((value) => {
            if (value.data.status === true) {
              // console.log("APRIAMO IL MODAL CF");
              if (
                isModalCFOpen === false &&
                getValues(questionField.slug) &&
                modalCFtrigger === false
              ) {
                setIdCandidaturaCFcheck(value.data.id);
                setNomeCFcheck(value.data.nome);
                setCognomeCFcheck(value.data.cognome);
                setIsModalCFOpen(true);
                setModalCFtrigger(true);
              }
            }

            return value.data.status;
          });
        }
      });
    });
  };

  const checkBirthDate = (dateInput) => {
    if (
      dateInput?.slug?.includes("birthdate") &&
      getValues(dateInput.slug) &&
      getValues(dateInput.slug) != oldDate
    ) {
      setOldDate(getValues(dateInput.slug));
      let dateOfBirthFieldValue = getValues(dateInput.slug);
      let bday = new Date(dateOfBirthFieldValue);
      let todayDate = new Date();
      let timeDiff = Math.abs(todayDate.getTime() - bday.getTime());
      let diffyears = Math.ceil(timeDiff / (1000 * 3600 * 24) / 365);
      console.log("L'utente ha: " + diffyears + " anni."); // or you can change value according to your requirement
      setUserAge(diffyears);
      setFormValuesStorage(dateInput, dateOfBirthFieldValue);
    }
  };

  const fileAssignPath = () => {
    domandeForm.areas.map((questionArea, areaIndex) => {
      questionArea.blocks.map((questionBlock, blockIndex) => {
        questionBlock.fields.map((questionField, fieldIndex) => {
          let temp = [];
          if (questionField.type === "file") {
            for (let i = 0; i < pdfFiles.length; i++) {
              if (pdfFiles[i].field === questionField.id) {
                temp.push(pdfFiles[i]);
              }
            }
            questionField.path = temp;
          }

          if (questionField.related) {
            questionField.related.map((questionBlockRel, blockRelIndex) => {
              questionBlockRel.fields.map((questionFieldRel, fieldRelIndex) => {
                let temp = [];
                if (questionFieldRel.type === "file") {
                  for (let i = 0; i < pdfFiles.length; i++) {
                    if (pdfFiles[i].field === questionFieldRel.id) {
                      temp.push(pdfFiles[i]);
                    }
                  }
                  questionFieldRel.path = temp;
                }
              });
            });
          }
        });

        // if (questionField.related) {
        //     questionField.related.fields.map((questionFieldRel, fieldRelIndex) => {
        //         pdfFiles.map((file, fileIndex) => {
        //             console.log("File blockId: ", file.relId);
        //             console.log("File field: ", file.field);
        //             console.log("Question field fieldId: ", questionFieldRel.id);
        //             if (questionFieldRel.type === "file" && file.field === questionFieldRel.id) {
        //                 questionFieldRel.path = file.path;
        //                 console.log("Il path di questionField[" + fieldRelIndex + "] è: ", questionFieldRel.path);
        //             }
        //         })
        //     })
        // }
      });
    });
  };

  const checkPDFfile = (id) => {
    if (document.readyState === "complete") {
      console.log("questionField.id : " + id);
      console.log("document getElementById: " + document.getElementById(id));
      let fileToCheck = document.getElementById(id);
      console.log("File to check: " + fileToCheck);

      pdfFiles.map((pdfFile, index) => {
        if (pdfFile === fileToCheck) {
          return pdfFile;
        }
      });
    }
  };

  const compileForm = () => {
    domandeForm?.areas?.map((questionArea, areaIndex) => {
      questionArea?.blocks?.map((questionBlock, blockIndex) => {
        {
          questionBlock?.fields?.map((questionField, fieldIndex) => {
            if (questionField.type === "file") {
              let temp = [];
              for (let i = 0; i < pdfFiles.length; i++) {
                if (pdfFiles[i].field === questionField.id) {
                  temp = questionField.value;
                  if (!temp.includes(pdfFiles[i].file.name)) {
                    temp.push(pdfFiles[i].file.name);
                  }
                }
              }
              questionField.value = temp;
              console.log("questionField.value :", questionField.value);
            }

            if (
              questionField.slug &&
              getValues(questionField.slug) &&
              questionField.type != "file"
            ) {
              console.log(getValues(questionField.slug));

              if (questionField.type === "date") {
                let newDate = formatDateField(getValues(questionField.slug));
                questionField.value = newDate;
              } else {
                questionField.value = getValues(questionField.slug);
              }

              console.log("QUESTION FIELD VALUE: " + questionField.value);
            }

            if (questionField.related) {
              {
                questionField.related.map((questionBlockRel, blockIndexRel) => {
                  questionBlockRel.fields.map(
                    (questionFieldRel, fieldIndexRel) => {
                      if (questionFieldRel.type === "file") {
                        let temp = [];
                        for (let i = 0; i < pdfFiles.length; i++) {
                          if (pdfFiles[i].field === questionFieldRel.id) {
                            temp = questionFieldRel.value;
                            if (!temp.includes(pdfFiles[i].file.name)) {
                              temp.push(pdfFiles[i].file.name);
                            }
                          }
                        }
                        questionFieldRel.value = temp;
                        console.log(
                          "questionFieldRel.value :",
                          questionFieldRel.value
                        );
                      }

                      if (
                        questionFieldRel.slug &&
                        getValues(questionFieldRel.slug) &&
                        questionFieldRel.type != "file"
                      ) {
                        console.log(getValues(questionFieldRel.slug));

                        if (questionFieldRel.type === "date") {
                          let newDate = formatDateField(
                            getValues(questionFieldRel.slug)
                          );
                          questionFieldRel.value = newDate;
                        } else {
                          questionFieldRel.value = getValues(
                            questionFieldRel.slug
                          );
                        }

                        console.log(
                          "QUESTION FIELD REL VALUE: " + questionFieldRel.value
                        );
                      }
                    }
                  );
                });
              }
            }
          });
        }
      });
    });

    console.log("SEND FORM: " + JSON.stringify(domandeForm));
  };

  const goBack = () => {
    alert("Torniamo indietro...");
  };

  const logTest = () => {
    {
      domandeForm.areas[area].blocks.map((questionBlock, index) => {
        console.log("Index map: " + index);
      });
    }
  };

  const logValues = () => {
    //console.log(JSON.stringify(domandeForm));
    console.log(getValues());
  };

  const CheckRelValue = (inputValue, relValue, field) => {
    let relCondition;
    let radioValue;

    if (relValue === "false") {
      relCondition = false;
    }

    if (relValue === "true") {
      relCondition = true;
    }

    let temp = getValues(field.slug);

    if (field.type === "radio") {
      if (getValues(field.slug) === "yes") {
        radioValue = true;
      } else {
        radioValue = false;
      }
    }

    // console.log("FIELD.RELATED: ", field.related);
    // console.log("GET VALUES DI QUESTION ANSWER ", field.slug, " : ", getValues(field.slug));
    // console.log("CHECK REL VALUE :", relValue);

    // console.log("INPUT VALUE : ", inputValue);

    if (field.type === "select") {
      return inputValue;
    }

    if (
      field.type === "radio"
        ? radioValue === relCondition
        : inputValue === relCondition
    ) {
      // console.log("RADIO VALUE : ", radioValue);
      // console.log("REL CONDITION : ", relCondition);
      // console.log("CHECK REL VALUE TRUE");
      //console.log("CHECK REL VALUE RITORNA TRUE");
      return true;
    } else {
      //console.log("CHECK REL VALUE RITORNA FALSE");
      return false;
    }
  };

  const skipModal = () => {
    setIsModalOpen(false);
    console.log("UPLOADING!");
    // for (const file of pdfFiles) {
    //     delete file.fieldId;
    // }
    if (pdfFiles.length > 0) {
      DataService.uploadFiles(pdfFiles, () => {
        fileAssignPath();
        if (!isBandoInterno) {
          DataService.sendForm(domandeForm);
        } else {
          console.log("SEND FORM RESPONSABILE!!");
          DataService.sendFormResponsabile(
            domandeForm,
            getValues("responsabile-eav"),
            getValues("mail-responsabile")
          );
        }
      });
    } else {
      if (!isBandoInterno) {
        DataService.sendForm(domandeForm);
      } else {
        console.log("SEND FORM RESPONSABILE!!");
        DataService.sendFormResponsabile(
          domandeForm,
          getValues("responsabile-eav"),
          getValues("mail-responsabile")
        );
      }
    }

    localStorage.clear();

    setArea((prevArea) => {
      const newArea = prevArea + 1;
      console.log("Domande form areas length : " + domandeForm.areas.length);
      console.log("Nuova area : " + newArea);
      return newArea;
    });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const CheckRelValueRiepilogo = (inputValue, relValue, field) => {
    let relCondition;
    let radioValue;

    console.log("______________________________");
    console.log("Input value: ", inputValue);
    console.log("relValue: ", relValue);
    console.log("field: ", field);
    console.log("______________________________");

    if (relValue === "false") {
      relCondition = false;
    } else {
      relCondition = true;
    }

    if (field && field.type === "radio") {
      if (getValues(field.slug) === "yes") {
        radioValue = true;
      } else {
        radioValue = false;
      }
    }

    if( field?.type === "select"){
      console.log("CASOOOOOOOOO")
      return inputValue;
    }else{
      if (
        field?.type === "radio"
          ? radioValue === relCondition
          : inputValue === relCondition
      ) {
        console.log(true);
        return true;
      } else {
        console.log(false);
        return false;
      }
    }

    
  };

  const changeCheckboxValue = (id) => {
    console.log("onChange del checkbox!");
    let checkboxField = document.getElementById(id);

    domandeForm.areas[area].map((questionArea, areaIndex) => {
      questionArea.blocks.map((questionBlock, blockIndex) => {
        questionBlock.fields.map((questionField, fieldIndex) => {
          if (id === questionField.id) {
            console.log("checkboxField.value : ", checkboxField.value);
            questionField.value = checkboxField.value;
          }
        });
      });
    });
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  // // Create an event handler so you can call the verification on button click event or form submit
  // const handleReCaptchaVerify = (async () => {
  //     if (!executeRecaptcha) {
  //         console.log('Execute recaptcha not yet available');
  //         return;
  //     }

  //     const token = await executeRecaptcha('yourAction');
  //     // Do whatever you want with the token
  // }, [executeRecaptcha]);

  // // You can use useEffect to trigger the verification as soon as the component being loaded
  // useEffect(() => {
  //     handleReCaptchaVerify();
  // }, [handleReCaptchaVerify]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdAWWQpAAAAAF9VHXRlntxR3lMlw7LkxGIHmwSy">
      <div className="h-full bg-gray-background">
        <div>
          <img
            src={require("../img/EAV-logo.png")}
            className="pl-12 pt-12 w-8/12 h-8/12 lg:w-2/5 lg:h-2/5"
          />
        </div>
        <hr className="mt-12"></hr>
        <div class="flex pl-2 pr-2 text-[9px] lg:pl-[104px] pt-6 lg:text-xs font-arial font-normal break-words">
          <span className="text-disabled-color">Candidature /</span>
          <span className="font-arial font-normal text-midnight-color pl-1">
            {domandeForm?.title}
          </span>
        </div>
        <div className="md:h-7 lg:h-7 mt-8 text-midnight-color font-arial text-xl md:text-2xl lg:text-2xl font-bold break-words text-center form-title-mq">
          {domandeForm?.title}
        </div>
        <div className="ml-4 mr-4 lg:ml-[104px] lg:mr-[104px] min-h-[520px] mt-8 bg-white rounded-s-md shadow-md font-arial break-words pb-16">
          {" "}
          {/*Inizio della card*/}
          {domandeForm?.areas &&
            area <= domandeForm?.areas?.length &&
            area >= 0 && (
              <div className="flex pt-12 pb-20 md:pl-10 items-center justify-center">
                {" "}
                {/*Inizio del div per i rounded-full*/}
                {area <= domandeForm?.areas?.length &&
                  domandeForm?.areas?.map((questionArea, index) => {
                    return (
                      <AreaRoundedFull
                        key={index}
                        areaNumber={index}
                        areaName={questionArea.title}
                        currentArea={area}
                      />
                    );
                  })}
                {domandeForm?.areas && area <= domandeForm?.areas?.length && (
                  <AreaRoundedFull
                    areaNumber={domandeForm?.areas?.length}
                    areaName={` ${domandeForm?.areas?.length +
                      1}. Riepilogo candidatura`}
                    currentArea={area}
                  />
                )}
              </div>
            )}
          <div className="pl-6 pr-6 md:pl-10 md:pr-10 lg:pl-10 lg:pr-10 text-midnight-color">
            <div className="text-xl md:text-2xl lg:text-2xl font-bold">
              {domandeForm?.areas[area]?.title}
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
              {domandeForm?.areas[area]?.blocks?.map(
                (questionBlock, blockIndex) => {
                  return (
                    <div key={blockIndex}>
                      <div
                        className={`md:text-lg lg:text-lg font-bold ${
                          questionBlock.title !== null ? "pt-10" : ""
                        }`}
                      >
                        {questionBlock.title}
                      </div>

                      <div
                        className={`md:text-lg lg:text-lg font-bold ${
                          questionBlock.title !== null ? "pt-10" : ""
                        }`}
                      >
                        {questionBlock.subtitle}
                      </div>

                      {buttonsError && (
                        <div className={`text-error-color text-xs font-bold`}>
                          Per proseguire, è necessario compilare le
                          dichiarazioni mancanti segnate di rosso
                        </div>
                      )}

                      <div
                        className={`grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-x-4 text-sm`}
                      >
                        {questionBlock?.fields?.map(
                          (questionAnswer, fieldIndex) => {
                            if (questionAnswer.type === "text") {
                              return (
                                <LabeledTextInput
                                  key={questionAnswer.slug}
                                  labelText={questionAnswer.label}
                                  placeholderText={questionAnswer.label}
                                  name={questionAnswer.slug}
                                  register={register}
                                  isRequired={questionAnswer.required}
                                  minLength={questionAnswer.minlength}
                                  maxLength={questionAnswer.maxlength}
                                  errors={errors}
                                  errorMsg="Campo obbligatorio"
                                  isInSubGrid={false}
                                  value={questionAnswer.value}
                                  col_span={questionAnswer.col_rel}
                                  onChange={(e) =>
                                    setFormValuesStorage(
                                      questionAnswer,
                                      e.target.value
                                    )
                                  }
                                  setUserVoteTrue={() => setUserVote(true)}
                                  setUserVoteFalse={() => setUserVote(false)}
                                  checkCFmethod={() => checkCFerror()}
                                  form={form}
                                  modalCFtrigger={modalCFtrigger}
                                  setModalCFtrigger={() =>
                                    setModalCFtrigger(false)
                                  }
                                />
                              );
                            }

                            if (questionAnswer.type === "checkbox") {
                              return (
                                <>
                                  <CheckboxComponent
                                    key={questionAnswer.slug}
                                    name={questionAnswer.slug}
                                    labelText={questionAnswer.label}
                                    register={register}
                                    isRequired={questionAnswer.required}
                                    errors={errors}
                                    errorMsg="Campo obbligatorio"
                                    index={fieldIndex}
                                    id={questionAnswer.id}
                                    onChange={() =>
                                      changeCheckboxValue(questionAnswer.id)
                                    }
                                  />

                                  {questionAnswer.related &&
                                    CheckRelValue(
                                      getValues(questionAnswer.slug),
                                      questionAnswer.related[0].if,
                                      questionAnswer
                                    ) && (
                                      <>
                                        <SubGridChecker
                                          questionAnswer={questionAnswer}
                                          register={register}
                                          errors={errors}
                                          pdfInputClick={handleInputClick}
                                          pdfInputChange={(e) =>
                                            handleInputChange(e, fieldIndex)
                                          }
                                          pdfAddToRef={AddToRef}
                                          fieldIndex={fieldIndex}
                                          userVote={userVote}
                                          setUserVote={setUserVote}
                                          //onChange={(e) => setFormValuesStorage(questionAnswer, e.target.value)}
                                        />
                                      </>
                                    )}
                                </>
                              );
                            }

                            if (questionAnswer.type === "date") {
                              return (
                                <>
                                  <DateInput
                                    key={questionAnswer.slug}
                                    name={questionAnswer.slug}
                                    labelText={questionAnswer.label}
                                    placeholderText={questionAnswer.label}
                                    register={register}
                                    isRequired={questionAnswer.required}
                                    errors={errors}
                                    errorMsg="Campo obbligatorio"
                                    userAge={
                                      questionAnswer?.slug?.includes(
                                        "birthdate"
                                      )
                                        ? userAge
                                        : null
                                    }
                                    onChange={checkBirthDate(questionAnswer)}
                                  />
                                </>
                              );
                            }

                            if (questionAnswer.type === "email") {
                              return (
                                <EmailInput
                                  key={questionAnswer.slug}
                                  name={questionAnswer.slug}
                                  labelText={questionAnswer.label}
                                  placeholderText={questionAnswer.label}
                                  register={register}
                                  isRequired={questionAnswer.required}
                                  errors={errors}
                                  errorMsg="Campo obbligatorio"
                                  minLength={questionAnswer.minlength}
                                  maxLength={questionAnswer.maxlength}
                                  onChange={(e) =>
                                    setFormValuesStorage(
                                      questionAnswer,
                                      e.target.value
                                    )
                                  }
                                />
                              );
                            }

                            if (questionAnswer.type === "number") {
                              return (
                                <NumberInput
                                  key={questionAnswer.slug}
                                  name={questionAnswer.slug}
                                  labelText={questionAnswer.label}
                                  placeholderText={questionAnswer.label}
                                  register={register}
                                  isRequired={questionAnswer.required}
                                  min={questionAnswer.min}
                                  max={questionAnswer.max}
                                  errors={errors}
                                  errorMsg="Campo obbligatorio"
                                  onChange={(e) =>
                                    setFormValuesStorage(
                                      questionAnswer,
                                      e.target.value
                                    )
                                  }
                                />
                              );
                            }

                            if (questionAnswer.type === "radio") {
                              return (
                                <div
                                  key={fieldIndex}
                                  className="col-span-4 flex flex-col pl-[34px]"
                                >
                                  <ChoiceRadioButton
                                    key={questionAnswer.slug}
                                    name={questionAnswer.slug}
                                    labelText={questionAnswer.label}
                                    placeholderText={questionAnswer.label}
                                    register={register}
                                    isRequired={questionAnswer.required}
                                    isRelated={questionAnswer.related}
                                    errors={errors}
                                    id={questionAnswer.id}
                                    errorMsg="Campo obbligatorio"
                                    // checkRelated={CheckRelValue(getValues(questionAnswer?.slug), questionAnswer?.related[0].if, questionAnswer)}
                                    onChange={() =>
                                      checkRelFileInput(questionAnswer)
                                    }
                                  />
                                  {questionAnswer.related &&
                                    questionAnswer.related?.length > 0 && (
                                      <div className="border-l-[0.5px] border-midnight-color pb-8 pl-[15px]">
                                        <div>
                                          {questionAnswer.related && (
                                            <SubGridChecker
                                              questionAnswer={questionAnswer}
                                              valueOfRel={CheckRelValue(
                                                getValues(questionAnswer.slug),
                                                questionAnswer.related[0].if,
                                                questionAnswer
                                              )}
                                              register={register}
                                              errors={errors}
                                              pdfInputClick={handleInputClick}
                                              pdfInputChange={handleInputChange}
                                              pdfAddToRef={AddToRef}
                                              fieldIndex={fieldIndex}
                                              onChange={(e) =>
                                                setFormValuesStorage(
                                                  questionAnswer,
                                                  e.target.value
                                                )
                                              }
                                              userVote={userVote}
                                              setUserVote={setUserVote}
                                              form={form}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    )}
                                </div>
                              );
                            }

                            if (questionAnswer.type === "file") {
                              return (
                                <>
                                  <FileInput
                                    key={questionAnswer.slug}
                                    name={questionAnswer.slug}
                                    labelText={questionAnswer.label}
                                    placeholderText={questionAnswer.labelText}
                                    inputRef={AddToRef}
                                    register={register}
                                    isRequired={questionAnswer.required}
                                    errors={errors}
                                    errorMsg="Campo obbligatorio"
                                    onClick={() =>
                                      handleInputClick(
                                        fieldIndex,
                                        questionAnswer.id
                                      )
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        fieldIndex,
                                        questionAnswer.id,
                                        questionBlock.id
                                      )
                                    }
                                    index={fieldIndex}
                                    id={questionAnswer.id}
                                  />
                                </>
                              );
                            }

                            // if (questionAnswer.type === "select") {
                            //     return (
                            //         <SelectInput
                            //             key={questionAnswer.slug}
                            //             name={questionAnswer.slug}
                            //             labelText={questionAnswer.label}
                            //             placeholderText={questionAnswer.label}
                            //             options={questionAnswer.options}
                            //             register={register}
                            //             isRequired={questionAnswer.required}
                            //             errors={errors}
                            //             errorMsg="Campo obbligatorio"
                            //             onChange={(e) => setFormValuesStorage(questionAnswer, e.target.value)}
                            //         />
                            //     )
                            // }

                            if (
                              questionAnswer.type === "select" &&
                              !questionAnswer.related
                            ) {
                              return (
                                <SelectInput
                                  key={questionAnswer.slug}
                                  name={questionAnswer.slug}
                                  labelText={questionAnswer.label}
                                  placeholderText={questionAnswer.label}
                                  options={questionAnswer.options}
                                  register={register}
                                  isRequired={questionAnswer.required}
                                  errors={errors}
                                  qa={questionAnswer}
                                  errorMsg="Campo obbligatorio"
                                  onChange={(e) =>
                                    setFormValuesStorage(
                                      questionAnswer,
                                      e.target.value
                                    )
                                  }
                                />
                              );
                            }

                            if (
                              questionAnswer.type === "select" &&
                              questionAnswer.related &&
                              questionAnswer.related?.length > 0
                            ) {
                              return (
                                <div
                                  key={fieldIndex}
                                  className="col-span-4 flex flex-col pl-[34px]"
                                >
                                  <SelectInput
                                    key={questionAnswer.slug}
                                    name={questionAnswer.slug}
                                    labelText={questionAnswer.label}
                                    placeholderText={questionAnswer.label}
                                    options={questionAnswer.options}
                                    register={register}
                                    isRequired={questionAnswer.required}
                                    errors={errors}
                                    qa={questionAnswer}
                                    errorMsg="Campo obbligatorio"
                                    onChange={(e) =>
                                      setFormValuesStorage(
                                        questionAnswer,
                                        e.target.value
                                      )
                                    }
                                  />
                                  <br />
                                  {questionAnswer.related &&
                                    questionAnswer.related?.length > 0 && (
                                      <div className="border-l-[0.5px] border-midnight-color pb-8 pl-[15px]">
                                        <div>
                                          {questionAnswer.related && (
                                            <SubGridChecker
                                              questionAnswer={questionAnswer}
                                              valueOfRel={CheckRelValue(
                                                getValues(questionAnswer.slug),
                                                questionAnswer.related[0].if,
                                                questionAnswer
                                              )}
                                              register={register}
                                              errors={errors}
                                              pdfInputClick={handleInputClick}
                                              pdfInputChange={handleInputChange}
                                              pdfAddToRef={AddToRef}
                                              fieldIndex={fieldIndex}
                                              onChange={(e) =>
                                                setFormValuesStorage(
                                                  questionAnswer,
                                                  e.target.value
                                                )
                                              }
                                              userVote={userVote}
                                              qa={questionAnswer}
                                              setUserVote={setUserVote}
                                              form={form}
                                              checkOnPreciseValue={true}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    )}
                                </div>
                              );
                            }
                          }
                        )}
                      </div>

                      {blockIndex <
                        domandeForm.areas[area].blocks.length - 1 && (
                        <hr className="mt-10 mr-10 border-[1px] mx-auto" />
                      )}
                    </div>
                  );
                }
              )}
            </Form>

            {area === domandeForm?.areas?.length && (
              <div className="font-arial text-midnight-color">
                <div className="text-xl md:text-2xl lg:text-2xl font-bold">
                  {area + 1}. Riepilogo Candidatura
                </div>

                <div className="pt-10 md:text-lg lg:text-lg">
                  Candidatura <b>{domandeForm?.title}</b> effettuata il{" "}
                  <b>{moment().format("DD/MM/YYYY")}</b> alle{" "}
                  <b>{moment().format("HH:mm")}</b>
                </div>

                <div className="pt-6">Riepilogo dei dati inseriti:</div>

                {domandeForm?.areas?.map((answerArea, areaIndex) =>
                  answerArea?.blocks?.map((answerBlock, blockIndex) => (
                    <div key={blockIndex}>
                      <div
                        className={` ${
                          blockIndex === 0 ? "pt-6" : "pt-10"
                        } font-bold text-lg`}
                      >
                        {blockIndex === 0 ? answerArea.title : ""}
                      </div>

                      <div
                        className={` ${
                          blockIndex === 0 ? "pt-6" : "pt-10"
                        } text-lg underline italic font-bold`}
                      >
                        {answerBlock.title}
                      </div>

                      <div
                        className={`pt-2 md:grid lg:grid md:grid-cols-2 lg:grid-cols-2 md:text-lg lg:text-lg`}
                      >
                        {answerBlock.fields.map((answerField, fieldIndex) => (
                          <>
                            {answerField.type != "radio" &&
                              answerField.type != "checkbox" && (
                                <div className="pt-6 flex overflow-hidden col-span-2">
                                  <div className="w-[200px] md:w-[150px] lg:w-[270px]">
                                    <label
                                      className={`font-bold text-sm lg:text-lg`}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          (answerField.label)?.replace(/<[^>]*>?/gm, '') +
                                          " " +
                                          (answerField.required ? "*" : ""),
                                      }}
                                    ></label>
                                  </div>
                                  <div className="">
                                    {answerField.type != "file" && (
                                      <label
                                        className={`pl-[5px] lg:pl-[30px] w-[130px] md:w-[150px] lg:w-[170px] text-sm lg:text-lg`}
                                      >
                                        {answerField.value}
                                      </label>
                                    )}

                                   

                                    {answerField.type === "file" &&
                                      answerField.value && (
                                        <div>
                                          {answerField.value.map(
                                            (value, valueIndex) => {
                                              return (
                                                <div key={valueIndex}>
                                                  <label className={``}>
                                                    {value}
                                                  </label>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}  


                                    {answerField.related &&
                                       (
                                        <div className="pl-4">
                                          <SubGridRiepilogo
                                            field={answerField}
                                          />
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}

                            {answerField.type === "checkbox" && (
                              <div className="pt-6 col-span-2">
                                <div className="flex">
                                  {answerField.value === true && (
                                    <div>
                                      <input
                                        type="checkbox"
                                        checked
                                        disabled
                                        className="w-[18px] h-[18px] flex-none"
                                      />
                                      <div className="text-lg text-error-color">
                                        {answerField.value}
                                      </div>
                                    </div>
                                  )}

                                  {answerField.value === "off" && (
                                    <input
                                      type="checkbox"
                                      disabled
                                      className="w-[18px] h-[18px] flex-none"
                                    />
                                  )}

                                  <p className="pl-4">
                                    {answerField.label}
                                    {answerField.required ? "*" : ""}
                                  </p>
                                </div>

                                {answerField.related &&
                                  CheckRelValueRiepilogo(
                                    getValues(answerField.slug),
                                    answerField.related[0].if
                                  ) && (
                                    <>
                                      <SubGridRiepilogo field={answerField} />
                                    </>
                                  )}
                              </div>
                            )}

                            {answerField.type === "radio" && (
                              <div className="pt-6 col-span-2">
                                <ChoiceRadioButton
                                  name={answerField.slug}
                                  labelText={answerField.label}
                                  register={register}
                                  isRequired={answerField.required}
                                  errors={errors}
                                  errorMsg={""}
                                  id={answerField.id}
                                  index={fieldIndex}
                                  disabled={true}
                                  checked={answerField.value}
                                />

                                {answerField.related &&
                                  CheckRelValueRiepilogo(
                                    getValues(answerField.slug),
                                    answerField.related[0].if,
                                    answerField
                                  ) && (
                                    <div className="pl-4">
                                      <SubGridRiepilogo field={answerField} />
                                    </div>
                                  )}
                              </div>
                            )}
                          </>
                        ))}
                      </div>

                      <hr className="mt-10 mr-10 border-[1px] mx-auto" />
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
          <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            proceedMethod={() => skipModal()}
          />
          <ModalCF
            open={isModalCFOpen}
            onClose={() => setIsModalCFOpen(false)}
            nome={nomeCFcheck}
            cognome={cognomeCFcheck}
            codiceCandidatura={domandeForm?.code}
            idCandidatura={idCandidaturaCFcheck}
          />
          {area > domandeForm?.areas?.length && (
            <>
              <ThankYouComponent />
            </>
          )}
        </div>{" "}
        {/*Fine della card*/}
        {area < domandeForm?.areas?.length + 1 && (
          <div
            className={`flex items-center justify-center mt-16 pb-16 ${
              area > domandeForm?.areas?.length ? "md:pb-72" : "md:pb-40"
            }`}
          >
            <EmptyButton
              buttonText={
                area < domandeForm?.areas?.length
                  ? "Annulla candidatura"
                  : area === domandeForm?.areas?.length
                  ? "Modifica candidatura"
                  : ""
              }
              methodToCall={() =>
                area <= domandeForm?.areas?.length && area > 0
                  ? goBackArea()
                  : backToBandi()
              }
            />
            <FullButton
              buttonText={
                area < domandeForm?.areas?.length
                  ? "Conferma e procedi"
                  : area === domandeForm?.areas?.length
                  ? "Invia candidatura"
                  : area > domandeForm?.areas?.length
                  ? "Ritorna alla home"
                  : ""
              }
              idForm="myForm"
              methodToCall={
                area === domandeForm?.areas?.length ? () => onSubmit() : ""
              }
            />
          </div>
        )}
        {area == domandeForm?.areas?.length + 1 && (
          <div className="mt-16 pb-16"></div>
        )}
      </div>
    </GoogleReCaptchaProvider>
  );
}
